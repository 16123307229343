// importing actions
import {
  LOAD_ALL_CONTACT_PERSON,
  VIEW_CONTACT_PERSON_BY_ID,
  LOAD_PARTNER_ORGS,
  VIEW_PARTNER_ORG_BY_ID,
  DELETE_PO,
  LOAD_BENEFICIARIES,
  VIEW_BENEFICIARY_BY_ID,
  DELETE_CONTACT_PERSON,
  EDIT_CONTACT_PERSON,
  EDIT_PO,
  LOAD_DASHBOARD_COUNT,
  LOAD_TRAINEE_REPORT,
  LOAD_ADMIN_AUDIT_TRAIL,
} from "../../types";

// state
const INITIAL_STATE = {
  pos: null,
  po: null,
  allContactPerson: null,
  contactPerson: null,
  allBeneficiaries: null,
  beneficiary: null,
  dashboardCounts: null,
  traineeReport: null,
  adminAuditTrail: null
};

// Redux reducer function
const adminReducer = (state = INITIAL_STATE, action) => {
  // Destructuring action
  const { type, payload } = action;

  switch (type) {
    case LOAD_PARTNER_ORGS:
      return {
        ...state,
        pos: payload,
      };

    case LOAD_DASHBOARD_COUNT:
      return {
        ...state,
        dashboardCounts: payload,
      };

    case LOAD_ADMIN_AUDIT_TRAIL:
      return {
        ...state,
        adminAuditTrail: payload,
      };

    case VIEW_PARTNER_ORG_BY_ID:
      return {
        ...state,
        po: payload,
      };
 

    case DELETE_PO:
      return {
        ...state,
        pos: state.pos.filter((po) => po.id !== payload),
      };

    case EDIT_PO:
      return Object.assign({}, state, {
        pos: state.pos.map((po) => {
          return po.id === payload.id ? { ...payload } : po;
        }),
      });

    case LOAD_ALL_CONTACT_PERSON:
      return {
        ...state,
        allContactPerson: payload,
      };
    case VIEW_CONTACT_PERSON_BY_ID:
      return {
        ...state,
        contactPerson: payload,
      };

    case EDIT_CONTACT_PERSON:
      return Object.assign({}, state, {
        allContactPerson: state.allContactPerson.map((contactPerson) => {
          return contactPerson.id === payload.id
            ? { ...payload }
            : contactPerson;
        }),
      });

    case DELETE_CONTACT_PERSON:
      return {
        ...state,
        allContactPerson: state.allContactPerson.filter(
          (cp) => cp.id !== payload
        ),
      };
    case LOAD_BENEFICIARIES:
      return {
        ...state,
        allBeneficiaries: payload,
      };
    case VIEW_BENEFICIARY_BY_ID:
      return {
        ...state,
        beneficiary: payload,
      };
    case LOAD_TRAINEE_REPORT:
      return {
        ...state,
        traineeReport: payload,
      };

    default:
      return state;
  }
};

export default adminReducer;
