import React, { useEffect, useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useDispatch, useSelector } from "react-redux";
import Styles from "./PartnerOrgs.module.css";
import { Button, Col, Row, Table } from "react-bootstrap";
import Info from "../../../components/TA_InfoCard/Info";
import {
  loadPartnerOrgs,
  deletePoByID,
  loadDashboardCount,
} from "../../../redux/admin/actions/admin.actions";

// Importing Icons
import { BsEye, BsSearch } from "react-icons/bs";
import { BiPencil, BiTrash } from "react-icons/bi";
import { FiPrinter, FiUpload } from "react-icons/fi";
import { ConfirmationModal } from "../../../components/Modal/ConfirmationModal";
import { Link } from "react-router-dom";
// import { ExportCsv } from '../../../components/Csv/ExportCsv';
import { LinearLoader } from "../../../components/Loader/LinearLoader";
import { UpdatePartnerOrgsModal } from "./UpdatePartnerOrgsModal/UpdatePartnerOrgsModal";
import dateFormat from "../../../helper/DateFormatter";
import { CSVLink } from "react-csv";
import Desc from "../../../components/Desc";
import ReactPaginate from "react-paginate";
import { CgArrowsExchangeAltV } from "react-icons/cg";

const PartnerOrgs = () => {
  const [openModal, setopenModal] = useState(false);
  const [openEditModal, setopenEditModal] = useState(false);
  const [partnerOrgToEdit, setpartnerOrgToEdit] = useState(null);

  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [poToDelete, setPoToDelete] = useState(null);

  const [tableAction, setTableAction] = useState(false);

  const { pos } = useSelector((state) => state.adminReducer);

  const { dashboardCounts } = useSelector((state) => state.adminReducer);

  const [sortingByNecaIdAccending, setsortingByNecaIdAccending] =
    useState(true);

  // const { pageNumber } = useParams() || 1;
  // pagination
  const [pageNumber, setPageNumber] = useState(0);
  const size = 10;
  const pagesVisited = pageNumber * size;
  const pageCount = Math.ceil(pos?.length / size);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  useEffect(() => {
    dispatch(loadDashboardCount());
  }, [dispatch]);

  useEffect(() => {
    setloading(true);

    dispatch(loadPartnerOrgs()).then(() => {
      setloading(false);
    });
  }, [dispatch]);

  const handleDelete = () => {
    dispatch(deletePoByID(poToDelete));
    setopenModal(false);
    dispatch(loadPartnerOrgs()).then(() => {
      setloading(false);
    });
  };

  // ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

  useEffect(() => {
    setData(
      pos
        ?.filter((po) => {
          return Object.values(po).some((name) =>
            String(name).toLowerCase().includes(search.toLowerCase())
          );
        })
        ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    );
  }, [pos, search]);

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle:
      "@page { size: auto; margin: 10mm; } @media print { body { -webkit-print-color-adjust: exact; padding: 40px !important; } }",
  });

  function delay(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  // Excel dataset
  const headers = [
    { label: "SN", key: "sn" },
    { label: "DATE CREATED", key: "datecreated" },
    { label: "PARTNER ORGANIZATION", key: "organization" },
    { label: "PARTNER ORGANIZATION ID", key: "necaId" },
    { label: "PHONE NO", key: "phoneno" },
    { label: "EMAIL", key: "email" },
  ];

  const values = pos?.map((po, index) => ({
    sn: index + 1,
    datecreated: dateFormat.DateYearMonthDay(po?.createdAt),
    organization: po?.organisationName,
    phoneno: po?.phoneNumber,
    email: po?.emailAddress,
  }));

  // sort by necaId
  const handleSortByNecaID = () => {
    const sortedData = pos?.sort((a, b) => {
      // necaId is a string so we need to convert it to number
      const aNecaId = Number(a?.necaId);
      const bNecaId = Number(b?.necaId);

      if (sortingByNecaIdAccending) {
        setsortingByNecaIdAccending(false);
        return aNecaId - bNecaId;
      }
      setsortingByNecaIdAccending(true);
      return bNecaId - aNecaId;
    });

    setData(sortedData);
  };

  return (
    <>
      <Desc title={`Partner Organizations`} />
      <React.Fragment>
        <div className={Styles.mainDashboardContent}>
          <nav
            aria-label='breadcrumb'
            style={{ maxWidth: "95%", margin: "auto" }}
          >
            <ol className='breadcrumb mt-3'>
              <li
                className={`${Styles.breadCrumb} breadcrumb-item`}
                aria-current='page'
              >
                Partner Organizations
              </li>
            </ol>
          </nav>
          <div className={Styles.tradeAreaCardCont}>
            <Info
              bgColor='#c4e4ff'
              color='#333333'
              cardText='TOTAL NO OF PARTNER ORGANIZATIONS'
              // value={dashboardCounts?.partnerOrganisationCount || "-"}
              value={data?.length || "-"}
            />
            <Info
              bgColor='#caf7d1'
              color='#333333'
              cardText='TOTAL NO OF TRAINEES'
              value={dashboardCounts?.beneficiariesCount || "-"}
            />
            {/* <Info
              bgColor='#fdeebe'
              color='#333333'
              cardText='NO OF POs IN GEO POLITICAL ZONES'
              value='-'
            /> */}
          </div>
          <section className={`${Styles.ActionContainer} bg-white`}>
            <section style={{ maxWidth: "95%", margin: "auto" }}>
              <Row className={`${Styles.action}`}>
                <Col className='align-items-center' sm={12} md={6}>
                  <div className='float-start'>
                    <CSVLink
                      headers={headers}
                      filename={`Partner Organizations ${dateFormat.DateYearMonthDay(
                        Date.now()
                      )} ${dateFormat.TimeOnly()}`}
                      data={!values ? "loading..." : values}
                    >
                      <Button
                        className={`${Styles.actionBtns} ${Styles.actionBtn1} me-3`}
                      >
                        Export To Excel
                        <FiUpload
                          className='ms-3'
                          style={{ fontSize: "16px" }}
                        />
                      </Button>
                    </CSVLink>

                    {/* <ExportCsv table="po" fileName="Partner Organizations" /> */}
                  </div>

                  <div>
                    <Button
                      className={`${Styles.actionBtns} ${Styles.actionBtn2} me-3`}
                      onClick={async () => {
                        setTableAction(true);
                        await delay(1000);
                        handlePrint();

                        setTableAction(false);
                      }}
                    >
                      Print
                      <FiPrinter
                        className='ms-3'
                        style={{ fontSize: "16px" }}
                      />
                    </Button>
                  </div>
                </Col>
                <Col className='text-center mt-4 mt-md-0' sm={12} md={6}>
                  <Link to='po-bulk-upload'>
                    <Button
                      className={`${Styles.actionBtns} ${Styles.actionBtn3} float-stat mx- float-md-end`}
                    >
                      BATCH UPLOAD
                    </Button>
                  </Link>
                  <Link to='add'>
                    <Button
                      className={`${Styles.actionBtns} ${Styles.actionBtn3} float-start mx-2 float-md-end`}
                    >
                      ADD NEW
                    </Button>
                  </Link>
                </Col>
              </Row>
            </section>
          </section>
          {/* LOADER */}
          {loading && <LinearLoader />}
          <section className='bg-white py-3 mt-5'>
            <section>
              <form
                style={{ maxWidth: "95%", margin: "auto" }}
                className={`form-group pb-3 ${Styles.hasSearch}`}
              >
                <BsSearch className={`${Styles.formControlSearchIcon}`} />
                <input
                  type='text'
                  className={`form-control form-control-sm shadow-none ${Styles.SearchBox}`}
                  placeholder='Search'
                  aria-label='Search'
                  onChange={handleChange}
                />
              </form>

              <section style={{ maxWidth: "95%", margin: "auto" }}>
                <Table borderless responsive id='po' ref={componentRef}>
                  <thead>
                    <tr
                      className={
                        tableAction ? Styles["trAction"] : Styles["thAction"]
                      }
                    >
                      <th colSpan='3'>
                        <div>
                          <p className='text-underline'>
                            Partner Organizations :{" "}
                            {dateFormat.DateYearMonthDay(Date.now())}{" "}
                            {dateFormat.TimeOnly()}{" "}
                          </p>
                        </div>
                      </th>
                    </tr>
                    <tr className={Styles.TableHeadRow}>
                      <th>SN</th>
                      <th>
                        <div
                          className='d-flex align-items-center align-items-center'
                          role='button'
                        >
                          DATE CREATED
                        </div>
                      </th>
                      <th
                        onClick={handleSortByNecaID}
                        role='button'
                        className='d-flex'
                      >
                        NECA ID
                        <div>
                          <CgArrowsExchangeAltV fontSize={18} />
                        </div>
                      </th>
                      <th>
                        <div
                          className='d-flex align-items-center align-items-center'
                          role='button'
                        >
                          PARTNER ORGANIZATIONS
                        </div>
                      </th>
                      <th>PHONE NO</th>
                      <th>EMAIL</th>
                      <th
                        className={
                          tableAction ? Styles["thAction"] : Styles["trAction"]
                        }
                      >
                        ACTIONS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(data) && data.length === 0 ? (
                      <tr>
                        <td colSpan='7' className='text-center py-4'>
                          Partner organizations list is empty.
                        </td>
                      </tr>
                    ) : (
                      data
                        ?.slice(pagesVisited, pagesVisited + size)
                        ?.map(
                          (
                            {
                              id,
                              createdAt,
                              necaId,
                              organisationName,
                              address,
                              phoneNumber,
                              emailAddress,
                              categories,
                              duration,
                            },
                            index
                          ) => {
                            return (
                              <React.Fragment key={id}>
                                <tr className={Styles.TableBodyRow}>
                                  <td>{pageNumber * size + index + 1}</td>
                                  <td>{createdAt.substring(0, 10)}</td>
                                  <td>{necaId}</td>
                                  <td>{organisationName}</td>
                                  <td>{phoneNumber}</td>
                                  <td>{emailAddress}</td>
                                  <td
                                    className={
                                      tableAction
                                        ? Styles["thAction"]
                                        : Styles["trAction"]
                                    }
                                  >
                                    <div className='d-flex gap-3 justify-content-between'>
                                      <Link to={`/admin/partnerOrg/${id}`}>
                                        <BsEye className={Styles.ViewIcon} />
                                      </Link>
                                      <BiPencil
                                        className={Styles.EditIcon}
                                        onClick={() => {
                                          setpartnerOrgToEdit({
                                            id,
                                            createdAt,
                                            organisationName,
                                            address,
                                            phoneNumber,
                                            emailAddress,
                                            categories,
                                            duration,
                                          });
                                          setopenEditModal(true);
                                        }}
                                      />
                                      <BiTrash
                                        onClick={() => {
                                          setPoToDelete(id);
                                          setopenModal(true);
                                        }}
                                        className={Styles.TrashIcon}
                                      />
                                    </div>
                                  </td>
                                </tr>
                              </React.Fragment>
                            );
                          }
                        )
                    )}
                  </tbody>
                </Table>
                {pos?.length > size && (
                  <ReactPaginate
                    previousLabel={"<<prev"}
                    nextLabel={"next>>"}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    breakLabel={"..."}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    containerClassName={Styles.paginationBtn}
                    previousLinkClassName={Styles.previousBtn}
                    nextLinkClassName={Styles.nextBtn}
                    disabledClassName={Styles.paginationDisabled}
                    activeClassName={Styles.paginationActive}
                  />
                )}
              </section>
            </section>
          </section>
          {/* DIALOG MODAL */}
          <ConfirmationModal
            show={openModal}
            onHide={() => setopenModal(false)}
            onClickYes={handleDelete}
            onClickNo={() => setopenModal(false)}
            instruction='Trainess in this organization will be deleted as well.'
          />

          {/* EDIT PARTNER INFORMATION MODAL */}
          <UpdatePartnerOrgsModal
            show={openEditModal}
            onHide={() => setopenEditModal(false)}
            record={partnerOrgToEdit}
          />
        </div>
      </React.Fragment>
    </>
  );
};

export default PartnerOrgs;
