import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import dateFormat from "../../../helper/DateFormatter";
import {
  loadTraineeById,
  loadTraineeEmploymentInfo,
} from "../../../redux/partnerOrgs/actions/partnerorgs.actions";
import { loadTraineeProfile } from "../../../redux/user/actions/user.actions";
import { Link } from "react-router-dom";
//importing Styles
import Styles from "./BeneficiaryDashboard.module.css";
import { Button, Col, Row } from "react-bootstrap";
import { UploadTraineeCvModal } from "../../PartnerOrgs/POTrainees/UploadTraineeCvModal/UploadTraineeCvModal";
import { UpdateTraineeEmploymentModal } from "../../PartnerOrgs/POTrainees/UpdateTraineeEmploymentModal/UpdateTraineeEmploymentModal";
import { ViewCvModal } from "../../PartnerOrgs/POTrainees/ViewCvModal/ViewCvModal";
import config from "../../../service/config";

// importing image
import avatar from "../../../assets/img/profile_placeholder.jpeg";
import { UploadTraineePicture } from "../../PartnerOrgs/POTrainees/UploadTraineePicture/UploadTraineePicture";

export const BeneficiaryDashboard = () => {
  const { userData } = useSelector((state) => state.userReducer);
  const [loading, setloading] = useState(true);
  const dispatch = useDispatch();

  //@employment modal
  const [openEmploymentEditModal, setopenEmploymentEditModal] = useState(false);
  const [employmentRecordToUpdate, setemploymentRecordToUpdate] =
    useState(null);

  //@cv upload modal
  const [openUploadCvModal, setopenUploadCvModal] = useState(false);

  //@view cv -modal
  const [openCVModal, setopenCVModal] = useState(false);

  //@image upload modal
  const [openUploadProfileModal, setopenUploadProfileModal] = useState(false);

  const {
    id,
    firstName,
    middleName,
    lastName,
    email,
    employmentStatus,
    partnerorganisation,
    phoneNumber,
    address,
    trainingBatch,
    graduationStatus,
    evicted,
    employ,
    city,
    state,
    dateOfBirth,
    profileImage,
    category,
    curriculumVitae,
  } = userData || {};

  useEffect(() => {
    if (userData) {
      setloading(true);
      dispatch(loadTraineeProfile());
      // dispatch(loadTraineeById(userData.id)).then(() => setloading(false));
      // dispatch(loadTraineeEmploymentInfo(userData.id));
    }
  }, [dispatch]);

  return (
    <React.Fragment>
      <div className={Styles.mainDashboardContent}>
        <nav
          aria-label='breadcrumb'
          style={{ maxWidth: "95%", margin: "auto" }}
        >
          <ol className='breadcrumb mt-3'>
            <li
              className={`${Styles.breadCrumb} breadcrumb-item`}
              aria-current='page'
            >
              Dashboard
            </li>
          </ol>
        </nav>

        <div className='bg-white mt-5 pb-5'>
          <div style={{ maxWidth: "95%", margin: "auto" }}>
            <div className='row flex-column-reverse d-flex flex-md-row'>
              <div className='col-md-6 border-right'>
                <div className='row mt-3'>
                  <h2 className='text-muted h5'>Bio</h2>
                  <div className='col-md-6 mt-3 col-12'>
                    <label className='mb-3'>Firstname</label>
                    <p className='border-0 form-control p-0 text-capitalize text-muted'>
                      {firstName}
                    </p>
                  </div>
                  <div className='col-md-6 mt-3 col-12'>
                    <label className='mb-3'>Lastname</label>
                    <p className='border-0 form-control p-0 text-capitalize text-muted'>
                      {lastName}
                    </p>
                  </div>
                  <div className='col-md-6 mt-3 col-12'>
                    <label className='mb-3'>Middlename</label>
                    <p className='border-0 form-control p-0 text-capitalize text-muted'>
                      {middleName}
                    </p>
                  </div>
                  <div className='col-md-6 mt-3 col-12'>
                    <label className='mb-3'>Phone number</label>
                    <p className='border-0 form-control p-0 text-capitalize text-muted'>
                      {phoneNumber}
                    </p>
                  </div>
                  <h2 className='text-muted h5 mt-5'>Organization</h2>
                  <div className='col-md-6 mt-3 col-12'>
                    <label className='mb-3'>Organization Name</label>
                    <p className='border-0 form-control p-0 text-capitalize text-muted'>
                      {partnerorganisation?.organisationName}
                    </p>
                  </div>
                  <div className='col-md-6 mt-3 col-12'>
                    <label className='mb-3'>Category Name</label>
                    <p className='border-0 form-control p-0 text-capitalize text-muted'>
                      {category?.categoryName}
                    </p>
                  </div>
                  <div className='col-md-6 mt-3 col-12'>
                    <label className='mb-3'>Category Description</label>
                    <p className='border-0 text-muted form-control p-0'>
                      {category?.categoryDescription}
                    </p>
                  </div>
                  <div className='col-md-6 mt-3 col-12'>
                    <label className='mb-3'>Duration</label>
                    <p className='border-0 form-control p-0 text-capitalize text-muted'>
                      {category?.duration}
                    </p>
                  </div>

                  <h2 className='text-muted h5 mt-5'>Graduation</h2>
                  <div className='col-md-6 mt-3 col-12'>
                    <label className='mb-3'>Batch</label>
                    <p className='border-0 form-control p-0 text-capitalize text-muted'>
                      {trainingBatch?.batchName}
                    </p>
                  </div>
                  <div className='col-md-6 mt-3 col-12'>
                    <label className='mb-3'>Graduation Status</label>
                    <p className='border-0 form-control p-0 text-capitalize text-muted'>
                      {graduationStatus}
                    </p>
                  </div>
                  <div className='col-md-6 mt-3 col-12'>
                    <label className='mb-3'>Reason</label>
                    <p className='border-0 form-control p-0 text-capitalize text-muted'>
                      {evicted?.reason || "N/A"}
                    </p>
                  </div>
                  <div className='col-md-6 mt-3 col-12'>
                    <label className='mb-3'>Dropped Out Date</label>
                    <p className='border-0 form-control p-0 text-capitalize text-muted'>
                      {dateFormat.DateYearMonthDay(evicted?.dateEvicted) ||
                        "N/A"}
                    </p>
                  </div>
                  <h2 className='text-muted h5 mt-5'>Employment</h2>
                  <div className='col-md-6 mt-3 col-12'>
                    <label className='mb-3'>Employment Status</label>
                    <p className='border-0 form-control p-0 text-capitalize text-muted'>
                      {employmentStatus}
                    </p>
                  </div>
                  <div className='col-md-6 mt-3 col-12'>
                    <label className='mb-3'>Employer</label>
                    <p className='border-0 form-control p-0 text-capitalize text-muted'>
                      {employ?.organisationName}
                    </p>
                  </div>
                  <div className='col-md-6 mt-3 col-12'>
                    <label className='mb-3'> Employment Date:</label>
                    <p className='border-0 form-control p-0 text-capitalize text-muted'>
                      {dateFormat.YearAlone(employ?.yearEmployed) || "N/A"}
                    </p>
                  </div>
                  <div className='col-md-6 mt-3 col-12'>
                    <label className='mb-3'>CV</label>
                    {curriculumVitae ? (
                      <a
                        href='#'
                        onClick={() => setopenCVModal(true)}
                        className='text-decoration-none text-blue d-block'
                        target='_self'
                      >
                        View
                      </a>
                    ) : (
                      <p className='border-0 form-control p-0 text-capitalize text-muted'>
                        Not uploaded
                      </p>
                    )}
                  </div>
                  <div className='col-md-6 mt-3 col-12 mt-5'>
                    <Button
                      onClick={() => {
                        setemploymentRecordToUpdate(userData);
                        setopenEmploymentEditModal(true);
                      }}
                      className='btn btn-primary rounded text-uppercase'
                    >
                      UPDATE EMPLOYMENT STATUS
                    </Button>
                  </div>
                  <div className='col-md-6 mt-3 col-12 mt-5'>
                    <Button
                      onClick={() => {
                        setopenUploadCvModal(true);
                      }}
                      className='btn btn-primary rounded text-uppercase'
                    >
                      Upload cv
                    </Button>
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='p-3'>
                  <div
                    className={` d-flex flex-column align-items-center text-center p-3`}
                    role='button'
                    onClick={() => {
                      setopenUploadProfileModal(true);
                    }}
                  >
                    {profileImage ? (
                      <div className={`${Styles.profileImageContainer} mt-5`}>
                        <div className={Styles.profileImageWrapper}>
                          <img
                            className={`rounded-circle`}
                            src={`${config.baseURL}/${profileImage}`}
                          />
                        </div>
                        <div className={`${Styles.profileImageOverlay}`}>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='23'
                            height='23'
                            fill='white'
                            className='bi bi-cloud-arrow-up'
                            viewBox='0 0 16 16'
                          >
                            <path
                              fill-rule='evenodd'
                              d='M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2z'
                            />
                            <path d='M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z' />
                          </svg>
                        </div>
                      </div>
                    ) : (
                      <div className={`${Styles.profileImageContainer} mt-5`}>
                        <div className={Styles.profileImageWrapper}>
                          <img
                            className={`rounded-circle`}
                            width='150px'
                            src={avatar}
                          />
                        </div>
                        <div className={`${Styles.profileImageOverlay}`}>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='23'
                            height='23'
                            fill='white'
                            className='bi bi-cloud-arrow-up'
                            viewBox='0 0 16 16'
                          >
                            <path
                              fill-rule='evenodd'
                              d='M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2z'
                            />
                            <path d='M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z' />
                          </svg>
                        </div>
                      </div>
                    )}
                    <Link
                      to={`update-profile/${id}`}
                      className='my-4 btn btn-primary rounded text-uppercase'
                    >
                      Update profile
                    </Link>
                    <span className='font-weight-bold'>
                      {firstName} {middleName} {lastName}
                    </span>
                    <span className='text-black-50'>{email}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* EDIT EMPLOYMENT MODAL */}
        {openEmploymentEditModal && (
          <UpdateTraineeEmploymentModal
            show={openEmploymentEditModal}
            onHide={() => setopenEmploymentEditModal(false)}
            record={employmentRecordToUpdate}
            trainingediting={true}
          />
        )}

        {/* UPLOAD PROFILE IMAGE MODAL */}
        {openUploadCvModal && (
          <UploadTraineeCvModal
            show={openUploadCvModal}
            onHide={() => setopenUploadCvModal(false)}
            setopenuploadcvmodal={setopenUploadCvModal}
            viewmodalafterupload={setopenCVModal}
            id={id}
            trainingediting={true}
          />
        )}

        {/* VIEW CV MODAL */}
        {openCVModal && (
          <ViewCvModal
            show={openCVModal}
            onHide={() => setopenCVModal(false)}
            curriculumvitaelink={curriculumVitae}
            trainingediting={true}
          />
        )}

        {/* UPLOAD PROFILE IMAGE MODAL */}
        {openUploadProfileModal && (
          <UploadTraineePicture
            show={openUploadProfileModal}
            onHide={() => setopenUploadProfileModal(false)}
            setopenuploadprofilemodal={setopenUploadProfileModal}
            id={id}
            trainingediting={true}
          />
        )}
      </div>
    </React.Fragment>
  );
};
