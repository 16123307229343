/* 
@example 
    
    export const ENDPOINT_ACTION_NAME = "/endpoint-url"
*/

//
export const TRADE_AREAS_URL = '/trade-areas';
export const PO_TRADE_AREAS_URL = '/trade-areas/categories/';
export const TRADE_POS_URL = '/trade-areas/partnerorg/';
export const UPDATE_PO_TRADE_AREAS="participating-organisation/categories"
