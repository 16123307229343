import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { updateTraineeCV } from "../../../../redux/partnerOrgs/actions/partnerorgs.actions";
import { loadTraineeProfile } from "../../../../redux/user/actions/user.actions";
import Styles from "./UploadTraineeCvModal.module.css";

export const UploadTraineeCvModal = (props) => {
  const dispatch = useDispatch();
  const [selectedCV, setselectedCV] = useState();
  const [loading, setloading] = useState(false);

  const handleChange = (event) => {
    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    setselectedCV(formData);
  };

  const handleUploadCV = () => {
    setloading(true);
    dispatch(updateTraineeCV(selectedCV, props.id))
      .then(() => {
        setloading(false);
        // training editing record
        props.trainingediting && dispatch(loadTraineeProfile());
        props.setopenuploadcvmodal(false);
        setTimeout(() => {
          props.viewmodalafterupload(true);
        }, 1000);
      })
      .catch(() => {
        setloading(false);
      });
  };

  return (
    <Modal
      {...props}
      size='xxl'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton className='border-0 px-3'></Modal.Header>
      <Modal.Body className='pb-5 pt-0'>
        <div className='mb-3'>
          <p className={Styles.heading}>Upload CV</p>
        </div>

        <form>
          <Row className='col mt-4'>
            <Col sm={12} md={12} xl={12} className='mb-4'>
              <Form.Control
                type='file'
                className={`${Styles.formInput} shadow-none`}
                name='file'
                onChange={handleChange}
              />
            </Col>
          </Row>
          <section className='mt-3 d-flex justify-content-center'>
            <Button
              onClick={handleUploadCV}
              className={`shadow-none mt-3 ${Styles.updateBtn}`}
              disabled={loading}
            >
              {loading ? "UPLOADING" : "UPLOAD"}
            </Button>
          </section>
        </form>
      </Modal.Body>
    </Modal>
  );
};
