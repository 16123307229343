import { toast } from "react-toastify";

/*

toast.info
toast.warning
toast.success

*/

// @General
export const somethingWentWrongToast = () =>
  toast.warning("Something Went Wrong");

export const upLoadSuccessfulToast = () => toast.success("Upload Successful");

// @Authentication
export const loginSuccessToast = () => toast.success("Login Success");
export const invalidCredentialToast = () =>
  toast.warning("Invalid Credentials");
export const passwordChangedSuccessToast = () =>
  toast.success("Password Change Successfully");

//@Trade Areas

export const addCategorySuccessToast = () =>
  toast.success("Trade Area Added Successfully");

export const deleteCategorySuccessToast = () =>
  toast.success("Trade Area Deleted Successfully");

export const updateCategorySuccessToast = () =>
  toast.success("Trade Area Updated Successfully");

export const updatePoCategorySuccessToast = () =>
  toast.success("Organization Trade Area Updated Successfully");

// @PO
export const userCreateSuccess = () =>
  toast.success("User Created Successfully");

export const recordAlreadyExistToast = () =>
  toast.warning("Record Already Exist");

export const poCreateSuccessToast = () =>
  toast.success("Profile Created Successfully");

export const poUpdatedSuccessToast = () =>
  toast.success("PO Updated Successfully");

export const poNotFoundToast = () => toast.warning("Profile Not Found");

export const deletePOSuccessToast = () =>
  toast.success("Partner Organization Deleted Successfully");

export const updatePoStateSuccessToast = () =>
  toast.success("Organization State Updated Successfully");

// @ContactPerson
export const contactPersonCreate = () =>
  toast.success("Contact Person Created Successfully");
export const deleteContactPersonToast = () =>
  toast.success("Contact Person Deleted Successfully");
export const updateContactPersonSuccessToast = () =>
  toast.success("Contact Person Updated Successfully");

//@batch
export const batchCreatedSuccessToast = () =>
  toast.success("Batch Created Successfully.");
export const deleteBatchToast = () =>
  toast.success("Batch Deleted Successfully");
export const updateBatchSuccessToast = () =>
  toast.success("Batch Updated Successfully");

//@trainees
export const traineeAddedSuccessToast = () =>
  toast.success("Trainee Added Successfully");
export const traineeAlreadyExistToast = () =>
  toast.warning("Record Already Exist");
export const traineeDeletedToast = () =>
  toast.success("Trainee Record Deleted");
export const updateTraineeRecordSuccessToast = () =>
  toast.success("Trainee Record Updated");

//@file upload
export const uploadProfileSuccess = () =>
  toast.success("Image update successfully");

//@location
export const lgaAddedSuccessfully = () =>
  toast.success("LGA added successfully");

export const lgaDeletedSuccessfully = () =>
  toast.success("LGA deleted successfully");
