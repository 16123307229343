import {
  invalidCredentialToast,
  loginSuccessToast,
  passwordChangedSuccessToast,
  somethingWentWrongToast,
} from "../../../components/NotificationToast";
import { LOAD_USER_PROFILE, LOGIN_SUCCESS } from "../../types";
import UserService from "../services/user.service";

//@login
export const login = (loginDetails, setSubmitting) => async (dispatch) => {
  try {
    const response = await UserService.tryLogin(loginDetails);
    // user not found
    if (
      response?.response?.status === 404 ||
      response?.response?.status === 401
    ) {
      invalidCredentialToast();
      setSubmitting(false);
    }

    // If user found
    if (response?.status === 200 || response?.status === true) {
      // Store token
      localStorage.setItem("jwt-token", response?.data?.accessToken);

      // update ui
      loginSuccessToast();

      // stop submitting form
      setSubmitting(false);

      // update state
      dispatch({
        type: LOGIN_SUCCESS,
        payload: response?.data?.userData,
      });

      // redirect user - routes
      if (response?.data?.userData.userType === "admin") {
        window.location.href = "/admin";
      } else if (response?.data?.userData.userType === "partnerOrganization") {
        window.location.href = "/podashboard";
      } else if (response?.data?.userData.userType === "beneficiary") {
        window.location.href = "/trainee";
      } else {
        return;
      }
    }

    // somethingWentWrongToast();
    setSubmitting(false);

    return response;
  } catch (error) {
    somethingWentWrongToast();
    setSubmitting(false);
    return error;
  }
};

//@load user profile
export const loadUserProfile = () => async (dispatch) => {
  try {
    const response = await UserService.fetchUserProfile();
    dispatch({
      type: LOAD_USER_PROFILE,
      payload: response.data.data,
    });
    return response;
  } catch (error) {
    somethingWentWrongToast();
  }
};

//@load user profile
export const loadTraineeProfile = () => async (dispatch) => {
  try {
    const response = await UserService.fetchTraineeProfile();
    console.log(response.data);
    dispatch({
      type: LOAD_USER_PROFILE,
      payload: response.data,
    });
    return response;
  } catch (error) {
    somethingWentWrongToast();
  }
};

//@change password
export const changePassword = (details, setSubmitting) => async (dispatch) => {
  try {
    const response = await UserService.changePassword(details);
    if (response.status === 200) {
      passwordChangedSuccessToast();
      setSubmitting(false);
    }
    return response;
  } catch (error) {
    somethingWentWrongToast();
    setSubmitting(false);

    return error;
  }
};

//@request forgot password reset
export const requestPasswordReset =
  (email, setSubmitting) => async (dispatch) => {
    try {
      const response = await UserService.requestPasswordReset(email);
      // console.log(response?.response?.data);
      // console.log(response?.response?.status === false);

      if (response?.response?.status === false) {
        alert("The Email is not registered with us");
        setSubmitting(false);
      }

      if (response?.status === 200) {
        alert(response.data.message);
        setSubmitting(false);
      }
      setSubmitting(false);
      return response;
    } catch (error) {
      somethingWentWrongToast();
      setSubmitting(false);
      return error;
    }
  };

//@ password reset
export const resetPassword = (details, setSubmitting) => async (dispatch) => {
  try {
    const response = await UserService.resetPassword(details);
    if (response.status === 200) {
      passwordChangedSuccessToast();
      setSubmitting(false);
      setTimeout(() => {
        
        window.location.href = "/";
      }, 2000);

    }
    // setSubmitting(false);
    // somethingWentWrongToast();

    return response;
  } catch (error) {
    somethingWentWrongToast();
    setSubmitting(false);

    return error;
  }
};

//@logout
export const logOut = () => (dispatch) => {
  localStorage.removeItem("jwt-token");

  // dispatch action to remove current user

  setTimeout(() => {
    window.location.href = "/";
  }, 2000);
};

// Create Contact
// export const createUser = (newContact, formActions) => async (dispatch) => {
//   try {
//     formActions.resetForm({});

//     const response = await UserService.createUser(newContact);

//     if (response?.status === 200 || response?.status === true) {
//       // update ui
//       contactPersonCreate();

//       // stop submitting form
//       formActions.resetForm({});
//       formActions.setSubmitting(false);
//       dispatch({
//         type: USER_CREATE_SUCCESS,
//         payload: response.data.data,
//       });
//     }
//   } catch (error) {
//     somethingWentWrongToast();
//     formActions.setSubmitting(false);
//   }
// };
