// importing actions

import {
  ADD_CATEGORY,
  EDIT_CATEGORY,
  DELETE_CATEGORY,
  LOAD_CATEGORIES,
  LOAD_PO_TRADE_AREAS,
  LOAD_TRADE_AREA_PO,
} from '../../types';

// state
const INITIAL_STATE = {
  tradeCategories: [],
  partnerOrgsTradeArea: null,
  tradeAreaPos: null,
};

// Redux reducer function
const tradeReducer = (state = INITIAL_STATE, action) => {
  // Destructuring action
  const { type, payload } = action;

  switch (type) {
    case ADD_CATEGORY:
      return {
        ...state,
        tradeCategories: [payload, ...state.tradeCategories],
      };
    case DELETE_CATEGORY:
      return {
        ...state,
        tradeCategories: state.tradeCategories.filter(
          (category) => category.id !== payload
        ),
      };

    case LOAD_CATEGORIES:
      return {
        ...state,
        tradeCategories: payload,
      };

    case EDIT_CATEGORY:
      return Object.assign({}, state, {
        tradeCategories: state.tradeCategories.map((category) => {
          return category.id === payload.id ? { ...payload } : category;
          // replace category with new detail if id match else leave it
        }),
      });

    case LOAD_PO_TRADE_AREAS:
      return {
        ...state,
        partnerOrgsTradeArea: payload,
      };

    case LOAD_TRADE_AREA_PO:
      return {
        ...state,
        tradeAreaPos: payload,
      };

    default:
      return state;
  }
};

export default tradeReducer;
