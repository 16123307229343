import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import LoadingBar from "react-top-loading-bar";

// Importing Components and Styles
import Navbar from "../../../components/Navbar/Navbar";
import Styles from "./AdminDashboardLayout.module.css";
import { Outlet, useNavigate } from "react-router-dom";
import routeSwapTitle from "./routeSwapTitle";
import SideNav from "../../../components/Sidebar/SideNav";
import { useLocation } from "react-router-dom";
import { loadUserProfile } from "../../../redux/user/actions/user.actions";

const AdminDashboardLayout = () => {
  const url = window.location.pathname;
  const [navTitle, setnavTitle] = useState("Dashboard");
  const path = useLocation();
  const navigate = useNavigate();
  const { userData } = useSelector((state) => state.userReducer);
  const [handleHamburger, sethandleHamburger] = useState(false);

  const dispatch = useDispatch();
  const [progress, setProgress] = useState(10);

  // Get token
  const thereIsToken = localStorage.getItem("jwt-token");

  // Set nav title each time there's a path change
  useEffect(() => {
    dispatch(loadUserProfile());

    setnavTitle(path.pathname);
  }, [dispatch, path]);

  useEffect(() => {
    if (!thereIsToken) {
      //logout
      navigate("/");
    }

    setProgress(60);

    if (thereIsToken) {
      dispatch(loadUserProfile()).then((res) => {
        setProgress(100);
        // true/false
        const isAdmin = res?.data?.data?.userType === "admin";

        if (!isAdmin) {
          //logout
          navigate("/");
        }
      });
    }
  }, [dispatch, navigate, thereIsToken]);

  // Responsiveness
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  return (
    <section className={Styles.adminDashboardCont}>
      <LoadingBar
        color='#27156f'
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />

      <div className={Styles.sidebarContOuter}>
        {isDesktopOrLaptop && <SideNav active={Styles.active} />}{" "}
      </div>

      {!isDesktopOrLaptop && handleHamburger && (
        <nav
          className={Styles.sidebarMobileShadow}
          onClick={() => sethandleHamburger(true)}
        >
          <div className={Styles.sidebarContOuter}>
            <SideNav active={Styles.active} />
          </div>
        </nav>
      )}

      <div className={Styles.mainContent}>
        <Navbar
          title={routeSwapTitle[url]}
          handleHamburger={handleHamburger}
          sethandleHamburger={sethandleHamburger}
        />

        {/* Component Children */}
        <Outlet />
      </div>
    </section>
  );
};

export default AdminDashboardLayout;
