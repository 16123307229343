import React, { useState } from "react";
import { useDispatch } from "react-redux";

// Importing Libraries
import { Link } from "react-router-dom";

// Importing icons
import {
  MdOutlineDashboard,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
} from "react-icons/md";
import { GoLightBulb } from "react-icons/go";
import { IoIosPeople } from "react-icons/io";
import { FiSettings, FiLogOut } from "react-icons/fi";
import { BsGraphUp } from "react-icons/bs";
import { AiOutlineQuestionCircle } from "react-icons/ai";

// Importing Components and Styles
import Styles from "./Sidebar_Beneficiary.module.css";
import Logo from "./images/logo.png";
import { logOut } from "../../redux/user/actions/user.actions";
import { useMediaQuery } from "react-responsive";

const Sidebar_Beneficiary = ({ active }) => {
  const dispatch = useDispatch();
  // States
  const [tradeareasActive, settradeareasActive] = useState(false);
  // const [partnerOrgs, setpartnerOrgs] = useState(false);
  const [trainees, settrainees] = useState(false);
  const [reports, setreports] = useState(false);

  // Responsiveness
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  return (
    <div className={Styles.sidebarCont}>
      {isDesktopOrLaptop && (
        <div className={Styles.sidebarLogo}>
          <img src={Logo} alt='Logo' />
        </div>
      )}
      <div className={Styles.sidebarContent}>
        <div className={Styles.sidebarContentInner}>
          <p className={Styles.sidebarLinksHeader}>General</p>
          <div>
            <div className='my-3'>
              <Link to='' className={Styles.sidebarLinks}>
                <p>
                  <MdOutlineDashboard className={`${active}`} />
                </p>
                <p>Dashboard</p>
              </Link>
            </div>

            {/* New Section */}
            <p className={Styles.sidebarLinksHeader}>Settings</p>
            <div className='my-3'>
              <Link to='change-password' className={Styles.sidebarLinks}>
                <p>
                  <FiSettings />
                </p>
                <p>Change Password</p>
              </Link>
            </div>
            <div className='my-3'>
              <Link to='support' className={Styles.sidebarLinks}>
                <p>
                  <AiOutlineQuestionCircle />
                </p>
                <p>Support</p>
              </Link>
            </div>

            {/* Logout */}
            <div
              className={Styles.logoutBtn}
              onClick={() => dispatch(logOut())}
            >
              <p>
                <FiLogOut />
              </p>
              <p>Logout</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar_Beneficiary;
