// importing actions

import {
  LOAD_USER_PROFILE,
  LOGIN_SUCCESS,
} from "../../types";

// state
const INITIAL_STATE = {
  userData: null,
  isLoggedIn: false,
};

// Redux reducer function
const userReducer = (state = INITIAL_STATE, action) => {
  // Destructuring action
  const { type, payload } = action;

  switch (type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        userData: payload,
        isLoggedIn: true,
      };
    case LOAD_USER_PROFILE:
      return {
        ...state,
        userData: payload,
        isLoggedIn: true,
      };
    default:
      return state;
  }
};

export default userReducer;
