import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

// Importing Components and Styles
import Navbar from "../../../components/Navbar/Navbar";
import Styles from "./PODashboardLayout.module.css";
import routeSwapTitle from "./routeSwapTitle";
import POSideNav from "../../../components/Sidebar_PO/POSideNav";
import { loadUserProfile } from "../../../redux/user/actions/user.actions";

import LoadingBar from "react-top-loading-bar";
import { useMediaQuery } from "react-responsive";

const PODashboardLayout = () => {
  const [navTitle, setnavTitle] = useState("Dashboard");
  const path = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [progress, setProgress] = useState(10);
  const [handleHamburger, sethandleHamburger] = useState(false);
  const { userData } = useSelector((state) => state.userReducer);

  // Responsiveness
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  // Get token
  const thereIsToken = localStorage.getItem("jwt-token");

  // Set nav title each time there's a path change
  useEffect(() => {
    setnavTitle(path.pathname);
  }, [path]);

  useEffect(() => {
    if (!thereIsToken) {
      //logout
      navigate("/");
    }

    setProgress(60);

    if (thereIsToken) {
      dispatch(loadUserProfile()).then((res) => {
        if (res) {
          setProgress(100);

          // true/false
          const isPo = res?.data?.data?.userType === "partnerOrganization";

          if (!isPo) {
            //logout
            navigate("/");
          }
        }
      });
    }
  }, [dispatch, navigate, thereIsToken]);

  return (
    <section className={Styles.adminDashboardCont}>
      {/* Loader  */}
      <LoadingBar
        color='#27156f'
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />

      {/* Responsiveness */}
      {/* {isDesktopOrLaptop || handleHamburger ? (
        <div className={Styles.sidebarContOuter}>
          <POSideNav active={Styles.active} />
        </div>
      ) : (
        ""
      )} */}

      <div className={Styles.sidebarContOuter}>
        {isDesktopOrLaptop && <POSideNav active={Styles.active} />}{" "}
      </div>

      {!isDesktopOrLaptop && handleHamburger && (
        <nav
          className={Styles.sidebarMobileShadow}
          onClick={() => sethandleHamburger(true)}
        >
          <div className={Styles.sidebarContOuter}>
            <POSideNav active={Styles.active} />
          </div>
        </nav>
      )}

      {/* Navbar */}
      <div className={Styles.mainContent}>
        <Navbar
          title={routeSwapTitle[navTitle]}
          handleHamburger={handleHamburger}
          sethandleHamburger={sethandleHamburger}
        />

        {/* Component Children */}
        <Outlet />
      </div>
    </section>
  );
};

export default PODashboardLayout;
