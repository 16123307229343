import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { Button, Modal, Row, Col, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Styles from "./UpdatePartnerOrgsModal.module.css";
import { editPO } from "../../../../redux/admin/actions/admin.actions";
import { loadCategories } from "../../../../redux/trade/actions/trade.actions";
import { SelectField } from "../../../../components/Form/SelectField";
import { loadStates } from "../../../../redux/locations/actions/locations.actions";
import { updateParticipatingOrganizationSchema } from "../../../../common/validation/Schema";

export const UpdatePartnerOrgsModal = (props) => {
  const dispatch = useDispatch();

  const [categoryDropDownOptions, setcategoryDropDownOptions] = useState();
  const [stateDropDownOptions, setstateDropDownOptions] = useState();

  useEffect(() => {
    dispatch(loadCategories()).then((response) => {
      let options = response.data.data
        .sort((a, b) => a.label - b.label)
        .map((tradeCategory) => ({
          label: tradeCategory.categoryName,
          value: tradeCategory.id,
        }));
      setcategoryDropDownOptions(options);
    });

    dispatch(loadStates()).then((response) => {
      let options = response.data.data
        .sort((a, b) => a.label - b.label)
        .map((state) => ({
          label: state.stateName,
          value: state.id,
        }));
      setstateDropDownOptions(options);
    });
  }, []);

  const durationDropDown = [
    { value: "1 Month", label: "1 Month" },
    { value: "2 Months", label: "2 Months" },
    { value: "3 Months", label: "3 Months" },
    { value: "4 Months", label: "4 Months" },
    { value: "5 Months", label: "5 Months" },
    { value: "6 Months", label: "6 Months" },
    { value: "7 Months", label: "7 Months" },
    { value: "8 Months", label: "8 Months" },
    { value: "9 Months", label: "9 Months" },
    { value: "10 Months", label: "10 Months" },
    { value: "11 Months", label: "11 Months" },
    { value: "12 Months", label: "12 Months" },
  ];
  console.log(props.record);
  return (
    <Modal
      {...props}
      size='xxl'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton className='border-0 px-3'></Modal.Header>
      <Modal.Body className='pb-5 pt-0'>
        <div className='mb-3'>
          <p>Update Partner Organization</p>
        </div>

        {props.record && (
          <Formik
            initialValues={{
              organisationName: props?.record?.organisationName,
              address: props?.record?.address,
              phoneNumber: props?.record?.phoneNumber,
              emailAddress: props?.record?.emailAddress,
              duration: {
                value: props?.record?.duration,
                label: props?.record?.duration,
              },
              // categories: props?.record?.categories.map((category) => {
              //   return { label: category.categoryName, value: category.id };
              // }),
            }}
            validationSchema={updateParticipatingOrganizationSchema}
            onSubmit={(values, { setSubmitting }) => {
              // retrieve id from the selected category
              // const selectedCategoryID = values.categories.map(
              //   (category) => category.value
              // );

              // update the categories
              // values.categories = selectedCategoryID;

              try {
                values.duration = values.duration.value;
                //
                dispatch(
                  editPO({ ...props.record, ...values }, setSubmitting)
                ).then((resp) => {
                  props.onHide();
                });
              } catch (error) {
                console.log(error);
              }
            }}
          >
            {({
              handleChange,
              handleSubmit,
              values,
              isSubmitting,
              setFieldValue,
              handleBlur,
              errors,
              touched,
            }) => (
              <React.Fragment>
                <form onSubmit={handleSubmit}>
                  {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                  <Row className='col mb-4'>
                    <Col className='mb-4' sm={12} md={12} xl={12}>
                      <Form.Control
                        type='text'
                        className={`${Styles.formInput} shadow-none`}
                        placeholder='Partner Organization'
                        name='organisationName'
                        onChange={handleChange}
                        value={values.organisationName || ""}
                      />
                      {errors.organisationName && touched.organisationName ? (
                        <p className='text-danger mt-1 mx-2'>
                          {errors.organisationName}
                        </p>
                      ) : null}
                    </Col>
                    <Col sm={12} md={12} xl={12}>
                      <Form.Control
                        type='text'
                        className={`${Styles.formInput} shadow-none`}
                        placeholder='Phone Number'
                        name='phoneNumber'
                        onChange={handleChange}
                        value={values.phoneNumber || ""}
                      />
                      {errors.phoneNumber && touched.phoneNumber ? (
                        <p className='text-danger mt-1 mx-2'>
                          {errors.phoneNumber}
                        </p>
                      ) : null}
                    </Col>
                  </Row>
                  <Row className='col'>
                    <Col className='mb-4' sm={12} md={12} xl={12}>
                      <Form.Control
                        type='text'
                        className={`${Styles.formInput} shadow-none`}
                        placeholder='Address'
                        name='address'
                        onChange={handleChange}
                        value={values.address || ""}
                      />
                      {errors.address && touched.address ? (
                        <p className='text-danger mt-1 mx-2'>
                          {errors.address}
                        </p>
                      ) : null}
                    </Col>
                  </Row>
                  <Row className='col'>
                    <Col className='mb-4' sm={12} md={12} xl={12}>
                      <Form.Control
                        type='text'
                        className={`${Styles.formInput} shadow-none`}
                        placeholder='Email Address'
                        name='emailAddress'
                        onChange={handleChange}
                        value={values.emailAddress || ""}
                      />
                      {errors.emailAddress && touched.emailAddress ? (
                        <p className='text-danger mt-1 mx-2'>
                          {errors.emailAddress}
                        </p>
                      ) : null}
                    </Col>
                    {/* <Col sm={12} md={12} xl={12}>
                      <SelectField
                        isMulti
                        className={`${Styles.formInput} shadow-none form-contro`}
                        options={categoryDropDownOptions}
                        name="categories"
                        onChange={setFieldValue}
                        value={values.categories}
                        placeholder="Select Trade Area"
                        onBlur={handleBlur}
                      />
                      {errors.categories && touched.categories ? (
                        <p className="text-danger mt-1 mx-2">
                          {errors.categories}
                        </p>
                      ) : null}
                    </Col> */}
                    <Col className='mb-4' sm={12} md={12} xl={12}>
                      <SelectField
                        className={`${Styles.formInput} shadow-none`}
                        options={durationDropDown}
                        name='duration'
                        onChange={setFieldValue}
                        value={values?.duration}
                        placeholder='Duration'
                        onBlur={handleBlur}
                      />
                      {errors.duration && touched.duration ? (
                        <p className='text-danger mt-1 mx-2'>
                          {errors.duration.value}
                        </p>
                      ) : null}
                    </Col>
                  </Row>
                  <section className='d-flex justify-content-center'>
                    <Button
                      className={`shadow-none mt-3 ${Styles.updateBtn}`}
                      disabled={isSubmitting}
                      type='submit'
                    >
                      {isSubmitting ? "Loading" : "UPDATE"}
                    </Button>
                  </section>
                </form>
              </React.Fragment>
            )}
          </Formik>
        )}
      </Modal.Body>
    </Modal>
  );
};
