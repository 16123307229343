// importing actions

import {
  ADD_BATCH,
  ADD_BENEFICIARY,
  DELETE_BATCH,
  DELETE_BENEFICIARY_BY_ID,
  EDIT_BATCH,
  LOAD_BATCHES,
  LOAD_BENEFICIARY,
  CLEAR_BENEFICIARY,
  LOAD_BENEFICIARY_REPORT,
  LOAD_PO_DASHBOARD_COUNT,
  PO_EDIT_BENEFICIARY,
  PO_LOAD_BENEFICIARY_BY_ID,
  LOAD_PO_AUDIT_TRAIL,
} from "../../types";

// state
const INITIAL_STATE = {
  beneficiaries: null,
  beneficiary: null,
  batches: [],
  dashboardCount: null,
  beneficiariesReport: null,
  POAuditTrails: null
};

// Redux reducer function
const partnerorgsReducer = (state = INITIAL_STATE, action) => {
  // Destructuring action
  const { type, payload } = action;
  switch (type) {
    case LOAD_PO_DASHBOARD_COUNT:
      return {
        ...state,
        dashboardCount: payload,
      };

    case LOAD_PO_AUDIT_TRAIL:
      return {
        ...state,
        POAuditTrails: payload
      };

    case LOAD_BENEFICIARY:
      return {
        ...state,
        beneficiaries: payload,
      };

    case CLEAR_BENEFICIARY:
      return {
        ...state,
        beneficiary: null,
      };

    case LOAD_BENEFICIARY_REPORT:
      return {
        ...state,
        beneficiariesReport: payload,
      };

    case ADD_BENEFICIARY:
      return {
        ...state,
        beneficiaries: [...state.beneficiaries, payload],
      };

    case PO_LOAD_BENEFICIARY_BY_ID:
      return {
        ...state,
        beneficiary: payload,
      };

    case PO_EDIT_BENEFICIARY:
      return {
        ...state,
        beneficiary: payload,
      };

    case DELETE_BENEFICIARY_BY_ID:
      return {
        ...state,
        beneficiaries: state.beneficiaries.filter(
          (beneficiary) => beneficiary.id !== payload
        ),
      };
    case LOAD_BATCHES:
      return {
        ...state,
        batches: payload,
      };
    case ADD_BATCH:
      return {
        ...state,
        batches: [payload, ...state.batches],
      };
    case EDIT_BATCH:
      return Object.assign({}, state, {
        batches: state.batches.map((batch) => {
          return batch.id === payload.id ? { ...payload } : batch;
        }),
      });
    case DELETE_BATCH:
      return {
        ...state,
        batches: state.batches.filter((batch) => batch.id !== payload),
      };
    default:
      return state;
  }
};

export default partnerorgsReducer;
