import axiosClient from "../../../service/axios";
import {
  CONTACT_PERSON_URL,
  PO_URL,
  BENEFICIARIES_URL,
  DASHBOARD_COUNT_URL,
  REPORT_URL,
  FETCH_ADMIN_AUDIT_TRAIL_URL,
  FETCH_CONTACT_PERSON_URL,
  PO_BULK_URL,
} from "./admin.endpoints";

/*
@get admin dashboard count

*/
const fetchDashboardCount = async () => {
  try {
    return await axiosClient.get(DASHBOARD_COUNT_URL);
  } catch (error) {
    return error;
  }
};

//@get system activities by user
const fetchAdminAuditTrail = async () => {
  try {
    return await axiosClient.get(FETCH_ADMIN_AUDIT_TRAIL_URL);
  } catch (error) {
    return error;
  }
};

/*
@get all PO

*/
const fetchAllPO = async (pageNumber = "", size = "") => {
  try {
    size = 400;
    return await axiosClient.get(PO_URL + `?page=${pageNumber}&size=${size}`);
  } catch (error) {
    return error;
  }
};

/*
@create PO

*/
const createNewPO = async (details) => {
  try {
    return await axiosClient.post(PO_URL, details);
  } catch (error) {
    return error;
  }
};

//@excel po profile upload
const bulkPOUpload = async (file) => {
  try {
    //upload file
    const response = await axiosClient.post(PO_BULK_URL, file);
    // const response = await axiosClient.post("https://meia-prod.herokuapp.com/file-upload/tester", file);

    return response;
  } catch (error) {
    return error;
  }
};

//@load po profile
const getPOByID = async (id) => {
  console.log(id);
  try {
    return await axiosClient.get(PO_URL + id);
  } catch (error) {
    return error;
  }
};

/*
@delete PO

*/
const deletePO = async (id) => {
  try {
    return await axiosClient.delete(PO_URL + id);
  } catch (error) {
    return error;
  }
};

/*
@edit PO

*/

const updatePO = async (details) => {
  try {
    return await axiosClient.put(PO_URL + details.id, details);
  } catch (error) {
    return error;
  }
};

// @create contact person
const createContactPerson = async (newUser) => {
  try {
    return await axiosClient.post(CONTACT_PERSON_URL, newUser);
  } catch (error) {
    return error;
  }
};

/*
@edit contact person

*/

const updateContactPerson = async (details) => {
  try {
    return await axiosClient.put(
      CONTACT_PERSON_URL + "/" + details.id,
      details
    );
  } catch (error) {
    return error;
  }
};

// @fetchAllContactPerson
const fetchAllContactPerson = async () => {
  try {
    return await axiosClient.get(FETCH_CONTACT_PERSON_URL);
  } catch (error) {
    return error;
  }
};

//@get single contact person by ID
const getContactPersonByID = async (id) => {
  try {
    return await axiosClient.get(CONTACT_PERSON_URL + id);
  } catch (error) {
    return error;
  }
};

// @delete contact person by ID
const deleteContactPerson = async (id) => {
  try {
    return await axiosClient.delete(CONTACT_PERSON_URL + id);
  } catch (error) {
    return error;
  }
};

// @fetchAllBeneficiaries
const fetchAllBeneficiaries = async (pageNumber = "", size = "") => {
  try {
    size = 400;
    return await axiosClient.get(
      BENEFICIARIES_URL + `?page=${pageNumber}&size=${size}`
    );
  } catch (error) {
    return error;
  }
};

//@get single beneficiary by ID
const getBeneficiaryByID = async (id) => {
  try {
    return await axiosClient.get(BENEFICIARIES_URL + id);
  } catch (error) {
    return error;
  }
};

//@get trainees report
const fetchTrainingReport = async () => {
  try {
    return axiosClient.get(REPORT_URL);
  } catch (error) {
    return error;
  }
};

const AdminService = {
  fetchAllPO,
  createNewPO,
  bulkPOUpload,
  getPOByID,
  deletePO,
  updatePO,
  createContactPerson,
  fetchAllContactPerson,
  getContactPersonByID,
  deleteContactPerson,
  fetchAllBeneficiaries,
  getBeneficiaryByID,
  updateContactPerson,
  fetchDashboardCount,
  fetchTrainingReport,
  fetchAdminAuditTrail,
};

export default AdminService;
