/* 
@example 
    
    export const ENDPOINT_ACTION_NAME = "/endpoint-url"
*/

// @Authentication/User
export const CITIES_URL = "/cities";
export const CITIES_BY_STATE_ID_URL = "/cities/state";
export const STATES_URL = "/states";
export const GEO_POLITICAL_ZONE_URL = "/zones";
export const PO_STATES_URL = "/states/po";
export const UPDATE_PO_STATE_URL = "/participating-organisation/states";
