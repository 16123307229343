import React, { useState } from 'react';

// Importing all Libraries
import { Link } from 'react-router-dom';
// import { useMediaQuery } from "react-responsive";
import {
  MdOutlineDashboard,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
} from 'react-icons/md';
import { GoLightBulb } from 'react-icons/go';
import { CgCalculator } from 'react-icons/cg';
import { IoIosPeople } from 'react-icons/io';
import { FiSettings, FiLogOut } from 'react-icons/fi';
import { BsGraphUp } from 'react-icons/bs';
import { AiOutlineQuestionCircle } from 'react-icons/ai';

// Importing all Components and Styles
import Styles from './SideNav.module.css';
import Logo from './images/logo.png';
import { logOut } from '../../redux/user/actions/user.actions';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import ITF from './images/itf.png';
import NECA from './images/necalogo.png';

const SideNav = ({ active }) => {
  // States
  const [tradeareasActive, settradeareasActive] = useState(false);
  const [partnerOrgs, setpartnerOrgs] = useState(false);
  const [trainees, settrainees] = useState(false);
  const [reports, setreports] = useState(false);
  const [activeTab, setactiveTab] = useState('default');

  const dispatch = useDispatch();

  const tradeAreaFunc = () => {
    settradeareasActive(!tradeareasActive);
    setactiveTab('trade');
  };

  const poFunc = () => {
    setpartnerOrgs(!partnerOrgs);
    setactiveTab('po');
  };

  const traineeFunc = () => {
    settrainees(!trainees);
    setactiveTab('trainee');
  };

  const reportFunc = () => {
    setreports(!reports);
    setactiveTab('report');
  };

  // Responsiveness
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)',
  });

  return (
    <div className={Styles.sidebarCont}>
      {isDesktopOrLaptop && (
        // <div className={Styles.sidebarLogo}>
        //   <img src={Logo} alt='Logo' />
        // </div>
        <div className='d-flex flex-column'>
          <div
            className={`d-flex justify-content-center align-items-center mb-3 mt-3 ${Styles.poweredBy}`}
          >
            <p>
              <img className={Styles.itf} src={ITF} alt='ITF' />
            </p>
            <p>
              <img className={Styles.neca} src={NECA} alt='NECA' />
            </p>
          </div>
          
        </div>
      )}
      <div className={Styles.sidebarContent}>
        <div className={Styles.sidebarContentInner}>
          <p className={Styles.sidebarLinksHeader}>General</p>
          <div>
            <div className='mt-3 mb-3'>
              <Link to='/admin' className={Styles.sidebarLinks}>
                <p>
                  <MdOutlineDashboard
                    className={`${activeTab === 'default' ? active : ''}`}
                  />
                </p>
                <p>Dashboard</p>
              </Link>
            </div>
            <div onClick={() => poFunc()} className='mt-3 mb-3'>
              <div className={`${Styles.sidebarLinks}`}>
                <p>
                  <CgCalculator
                    className={`${activeTab === 'po' ? active : ''}`}
                  />
                </p>
                <p>POs</p>
                <p>
                  {partnerOrgs ? (
                    <MdKeyboardArrowUp />
                  ) : (
                    <MdKeyboardArrowDown />
                  )}
                </p>
              </div>
              {partnerOrgs && (
                <>
                  <Link to='/admin/partnerorg'>
                    <p className={`pt-3 ${Styles.tradeAreasInner}`}>
                      Partner Organizations
                    </p>
                  </Link>
                  <Link to='/admin/contactperson'>
                    <p className={`pt-2 pl-3 ${Styles.tradeAreasInner}`}>
                      Contact Persons
                    </p>
                  </Link>
                </>
              )}
            </div>
            <div onClick={() => tradeAreaFunc()} className='mt-3 mb-3'>
              <div className={`${Styles.sidebarLinks}`}>
                <p>
                  <GoLightBulb
                    className={`${activeTab === 'trade' ? active : ''}`}
                  />
                </p>
                <p onClick={() => setactiveTab(true)}>Trade Areas</p>
                <p>
                  {tradeareasActive === 'tradeareas' ? (
                    <MdKeyboardArrowUp />
                  ) : (
                    <MdKeyboardArrowDown />
                  )}
                </p>
              </div>
              {tradeareasActive && (
                <React.Fragment>
                  <Link to='/admin/tradeareas'>
                    <p className={`pt-3 ${Styles.tradeAreasInner}`}>
                      Trade Areas
                    </p>
                  </Link>
                  {/* <Link to="tradeareas/addCategory">
                    <p className={`pt-2 ${Styles.tradeAreasInner}`}>
                      Add Category
                    </p>
                  </Link> */}
                  <Link to='tradeareas/viewcategory'>
                    <p className={`pt-2 ${Styles.tradeAreasInner}`}>Trades</p>
                  </Link>
                </React.Fragment>
              )}
            </div>
            <div onClick={() => traineeFunc()} className='mt-3 mb-3'>
              <div className={`${Styles.sidebarLinks}`}>
                <p>
                  <IoIosPeople
                    className={`${activeTab === 'trainee' ? active : ''}`}
                  />
                </p>
                <p>Trainees</p>
                <p>
                  {trainees ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
                </p>
              </div>
              {trainees && (
                <Link to='/admin/trainees'>
                  <p className={`pt-3 ${Styles.tradeAreasInner}`}>Trainees</p>
                </Link>
              )}
            </div>
            <div onClick={() => reportFunc()} className='mt-3 mb-3'>
              <div className={`mb-3 ${Styles.sidebarLinks}`}>
                <p>
                  <BsGraphUp
                    className={`${activeTab === 'report' ? active : ''}`}
                  />
                </p>
                <p>Reports</p>
                <p>
                  {reports ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
                </p>
              </div>
              {reports && (
                <>
                  <Link to='/admin/reports'>
                    <p className={`pt-2 ${Styles.tradeAreasInner}`}>
                      State Reports
                    </p>
                  </Link>
                  <Link to='/admin/reports/gender'>
                    <p className={`pt-2 ${Styles.tradeAreasInner}`}>
                      Gender Reports
                    </p>
                  </Link>
                  <Link to='/admin/reports/beneficiaries'>
                    <p className={`pt-2 ${Styles.tradeAreasInner}`}>
                      Trade Area Reports
                    </p>
                  </Link>
                  {/* <Link to="/admin/reports/training">
                    <p className={`pt-2 ${Styles.tradeAreasInner}`}>
                      Training Reports
                    </p>
                  </Link> */}
                  <Link to='/admin/reports/graduation'>
                    <p className={`pt-2 ${Styles.tradeAreasInner}`}>
                      Graduation Reports
                    </p>
                  </Link>
                  {/* <Link to="/admin/reports/pdf">
                    <p className={`pt-2 ${Styles.tradeAreasInner}`}>
                      PDF Reports
                    </p>
                  </Link> */}
                </>
              )}
            </div>

            {/* New Section */}
            <p className={Styles.sidebarLinksHeader}>Settings</p>
            <div className='mt-3 mb-3'>
              <Link to='/admin/settings' className={Styles.sidebarLinks}>
                <p>
                  <FiSettings />
                </p>
                <p>Settings</p>
              </Link>
            </div>
            {/* <div className='mt-3 mb-3'>
              <Link to='/admin/support' className={Styles.sidebarLinks}>
                <p>
                  <AiOutlineQuestionCircle />
                </p>
                <p>Support</p>
              </Link>
            </div> */}

            {/* Logout */}
            <div
              className={Styles.logoutBtn}
              onClick={() => dispatch(logOut())}
            >
              <p>
                <FiLogOut />
              </p>
              <p>Logout</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideNav;
