import axiosClient from '../../../service/axios';
import {
  PO_TRADE_AREAS_URL,
  TRADE_AREAS_URL,
  TRADE_POS_URL,
  UPDATE_PO_TRADE_AREAS,
} from './trade.endpoints';

const newCategory = async (details) => {
  try {
    return await axiosClient.post(TRADE_AREAS_URL, details);
  } catch (error) {
    return error;
  }
};

const fetchCategories = async () => {
  try {
    return await axiosClient.get(TRADE_AREAS_URL);
  } catch (error) {
    return error;
  }
};

/*

@ delete category
*/
const deleteCategories = async (id) => {
  try {
    return await axiosClient.delete(TRADE_AREAS_URL + '/' + id);
  } catch (error) {
    return error;
  }
};

/*

@edit category

*/
const updateCategory = async (details) => {
  try {
    return await axiosClient.put(TRADE_AREAS_URL + '/' + details.id, details);
  } catch (error) {
    return error;
  }
};

/*

@edit po category

*/
const updatePoCategory = async (details) => {
  try {
    return await axiosClient.put(UPDATE_PO_TRADE_AREAS + '/' + details.id, details);
  } catch (error) {
    return error;
  }
};

/*

@get po trade area

*/
const fetchPOTradeAreas = async (poId) => {
  try {
    return await axiosClient.get(PO_TRADE_AREAS_URL + poId);
  } catch (error) {
    return error;
  }
};

/*

@get pos in a trade area

*/
const fetchTradeAreasPo = async (id) => {
  try {
    return await axiosClient.get(TRADE_POS_URL + id);
  } catch (error) {
    return error;
  }
};

const TradeService = {
  newCategory,
  fetchCategories,
  fetchPOTradeAreas,
  deleteCategories,
  updateCategory,
  fetchTradeAreasPo,
  updatePoCategory
};

export default TradeService;
