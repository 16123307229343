import axiosClient from "../../../service/axios";
import {
  FETCH_TRAINEE_PROFILE_URL,
  FETCH_USER_PROFILE_URL,
  REQUEST_FORGOT_PASSWORD_RESET_URL,
  CHANGE_PASSWORD_URL,
  USER_LOGIN_URL,
  RESET_FORGOT_PASSWORD_URL,
} from "./user.endpoints";

//@create login session
const tryLogin = async (loginDetails) => {
  try {
    return await axiosClient.post(USER_LOGIN_URL, loginDetails);
  } catch (error) {
    return error;
  }
};

//@get user profile
const fetchUserProfile = async () => {
  try {
    return await axiosClient.get(FETCH_USER_PROFILE_URL);
  } catch (error) {
    return error;
  }
};

//@get user profile
const fetchTraineeProfile = async () => {
  try {
    return await axiosClient.get(FETCH_TRAINEE_PROFILE_URL);
  } catch (error) {
    return error;
  }
};

//@change password
const changePassword = async (details) => {
  try {
    return await axiosClient.post(CHANGE_PASSWORD_URL, details);
  } catch (error) {
    return error;
  }
};

//@request forgot password
const requestPasswordReset = async (email) => {
  try {
    return await axiosClient.post(REQUEST_FORGOT_PASSWORD_RESET_URL, email);
  } catch (error) {
    return error;
  }
};

//@reset password
const resetPassword = async (details) => {
  try {
    return await axiosClient.post(RESET_FORGOT_PASSWORD_URL, details);
  } catch (error) {
    return error;
  }
};

const UserService = {
  tryLogin,
  fetchUserProfile,
  changePassword,
  fetchTraineeProfile,
  requestPasswordReset,
  resetPassword
};

export default UserService;
