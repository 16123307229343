import React, { useState } from "react";
import { useDispatch } from "react-redux";

// Importing Libraries
import { Link } from "react-router-dom";

// Importing icons
import {
  MdOutlineDashboard,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
} from "react-icons/md";
import { GoLightBulb } from "react-icons/go";
import { IoIosPeople } from "react-icons/io";
import { FiSettings, FiLogOut } from "react-icons/fi";
import { BsGraphUp } from "react-icons/bs";
import { AiOutlineQuestionCircle } from "react-icons/ai";

// Importing Components and Styles
import Styles from "./POSideNav.module.css";
import Logo from "./images/logo.png";
import { logOut } from "../../redux/user/actions/user.actions";
import { useMediaQuery } from "react-responsive";
import ITF from './images/itf.png';
import NECA from './images/necalogo.png';

const POSideNav = ({ active }) => {
  const dispatch = useDispatch();
  // States
  const [tradeareasActive, settradeareasActive] = useState(false);
  // const [partnerOrgs, setpartnerOrgs] = useState(false);
  const [trainees, settrainees] = useState(false);
  const [reports, setreports] = useState(false);

  // Responsiveness
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  return (
    <div className={Styles.sidebarCont}>
      {isDesktopOrLaptop && (
         <div className='d-flex flex-column'>
          <div
            className={`d-flex justify-content-center align-items-center mb-3 mt-3 ${Styles.poweredBy}`}
          >
            <p>
              <img className={Styles.itf} src={ITF} alt='ITF' />
            </p>
            <p>
              <img className={Styles.neca} src={NECA} alt='NECA' />
            </p>
          </div>
         
        </div>
      )}
      <div className={Styles.sidebarContent}>
        <div className={Styles.sidebarContentInner}>
          <p className={Styles.sidebarLinksHeader}>General</p>
          <div>
            <div className='my-3'>
              <Link to='' className={Styles.sidebarLinks}>
                <p>
                  <MdOutlineDashboard className={`${active}`} />
                </p>
                <p>Dashboard</p>
              </Link>
            </div>
            <div
              onClick={() => settradeareasActive(!tradeareasActive)}
              className='my-3'
            >
              <div className={Styles.sidebarLinks}>
                <p>
                  <GoLightBulb />
                </p>
                <p>Trade Areas</p>
                <p>
                  {tradeareasActive === "tradeareas" ? (
                    <MdKeyboardArrowUp />
                  ) : (
                    <MdKeyboardArrowDown />
                  )}
                </p>
              </div>
              {tradeareasActive && (
                <Link to='tradeareas'>
                  <p className={`pt-3 ${Styles.tradeAreasInner}`}>
                    Trade Areas
                  </p>
                </Link>
              )}
            </div>
            {/* <div onClick={() => setpartnerOrgs(!partnerOrgs)}>
              <div className={Styles.sidebarLinks}>
                <p>
                  <CgCalculator />
                </p>
                <p>POs</p>
                <p>
                  {partnerOrgs ? (
                    <MdKeyboardArrowUp />
                  ) : (
                    <MdKeyboardArrowDown />
                  )}
                </p>
              </div>
              {partnerOrgs && (
                <Link to="partnerorg">
                  <p className={`pt-2 ${Styles.tradeAreasInner}`}>
                    Partner Origanizations
                  </p>
                </Link>
              )}
            </div> */}
            <div onClick={() => settrainees(!trainees)} className='my-3'>
              <div className={`${Styles.sidebarLinks}`}>
                <p>
                  <IoIosPeople />
                </p>
                <p>Trainees</p>
                <p>
                  {trainees ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
                </p>
              </div>
              {trainees && (
                <>
                  <Link to='trainees'>
                    <p className={`pt-3 ${Styles.tradeAreasInner}`}>Trainees</p>
                  </Link>
                  <Link to='trainees/trainingbatch'>
                    <p className={`${Styles.tradeAreasInner}`}>
                      Training Batch
                    </p>
                  </Link>
                </>
              )}
            </div>
            <div onClick={() => setreports(!reports)} className='my-3'>
              <div className={Styles.sidebarLinks}>
                <p>
                  <BsGraphUp />
                </p>
                <p>Reports</p>
                <p>
                  {reports ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
                </p>
              </div>
              {reports && (
                <Link to='reports'>
                  <p className={`pt-2 ${Styles.tradeAreasInner}`}>Reports</p>
                </Link>
              )}
            </div>

            {/* New Section */}
            <p className={Styles.sidebarLinksHeader}>Settings</p>
            <div className='my-3'>
              <Link to='settings' className={Styles.sidebarLinks}>
                <p>
                  <FiSettings />
                </p>
                <p>Settings</p>
              </Link>
            </div>
            <div className='my-3'>
              <Link to='support' className={Styles.sidebarLinks}>
                <p>
                  <AiOutlineQuestionCircle />
                </p>
                <p>Support</p>
              </Link>
            </div>

            {/* Logout */}
            <div
              className={Styles.logoutBtn}
              onClick={() => dispatch(logOut())}
            >
              <p>
                <FiLogOut />
              </p>
              <p>Logout</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default POSideNav;
