import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Styles from "./manage-state.module.css";
import { Button, Col, Row, Table } from "react-bootstrap";
// Importing Icons
import { BsEye, BsSearch } from "react-icons/bs";
import { BiPencil, BiTrash } from "react-icons/bi";
// Importing Icons
import { FiPrinter } from "react-icons/fi";
import { Link, useParams } from "react-router-dom";
import {
  deleteStateLGA,
  loadStateLGA,
  loadStates,
} from "../../../../redux/locations/actions/locations.actions";
import { LinearLoader } from "../../../../components/Loader/LinearLoader";
import dateFormat from "../../../../helper/DateFormatter";
import { NewLgaModal } from "./NewLgaModal";

const ManageState = () => {
  const dispatch = useDispatch();
  const [loading, setloading] = useState(null);
  const { stateId } = useParams();
  const [stateLGA, setstateLGA] = useState([]);
  const [state, setState] = useState([]);
  const [openAddModal, setopenAddModal] = useState(false);

  useEffect(() => {
    setloading(true);
    if (stateId) {
      dispatch(loadStateLGA(stateId))
        .then((res) => {
          setstateLGA(res.data.data.cities);
          setState(res.data.data.state);
          setloading(false);
        })
        .catch((error) => console.log(error));
    }
  }, [dispatch]);

  return (
    <React.Fragment>
      <nav aria-label='breadcrumb' style={{ maxWidth: "95%", margin: "auto" }}>
        <ol className='breadcrumb mt-3'>
          <li className='breadcrumb-item'>
            <Link
              to='/admin/settings/states'
              className={`${Styles.breadCrumb}`}
              style={{ textDecoration: "none" }}
            >
              States
            </Link>
          </li>
          <li
            className={`${Styles.breadCrumb} breadcrumb-item`}
            aria-current='page'
          >
            {state.stateName || "Loading..."}
          </li>
        </ol>
      </nav>
      <section className={`${Styles.ActionContainer} bg-white`}>
        <section style={{ maxWidth: "95%", margin: "auto" }}>
          <Row className={`${Styles.action}`}>
            <Col className='mt-4 mt-md-0' sm={12} md={12} lg={12}>
              <Button
                className={`${Styles.actionBtns} ${Styles.actionBtn3} float-start mx-2 float-md-end`}
                onClick={() => {
                  setopenAddModal(true);
                }}
              >
                ADD NEW
              </Button>
            </Col>
          </Row>
        </section>
      </section>
      <section style={{ maxWidth: "95%", margin: "auto" }}>
        <Table borderless responsive>
          <thead>
            <tr className={Styles.TableHeadRow}>
              <th>SN</th>
              <th>
                <div className='d-flex align-items-center align-items-center'>
                  NAME
                </div>
              </th>
              <th>ACTIONS</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(stateLGA) && stateLGA.length === 0 ? (
              <tr>
                <td colSpan='7' className='text-center py-4'>
                  No LGA Record Found
                </td>
              </tr>
            ) : (
              stateLGA?.map(({ id, cityName, createdAt }, index) => {
                return (
                  <React.Fragment key={id + cityName}>
                    <tr className={Styles.TableBodyRow}>
                      <td>{index + 1}</td>
                      <td>{cityName}</td>

                      {/* <td>{dateFormat.TimeAgo(createdAt.substring(0, 10))}</td> */}
                      <td>
                        <div className='d-flex justify-content-between w-25'>
                          {/* <BiPencil
                            className={Styles.EditIcon}
                            onClick={() => {
                                setpartnerOrgToEdit({
                                  id,
                                  createdAt,
                                  organisationName,
                                  address,
                                  phoneNumber,
                                  emailAddress,
                                  categories,
                                  duration,
                                });
                                setopenEditModal(true);
                            }}
                          /> */}
                          <BiTrash
                            onClick={() => {
                              const shouldDeleteLga = window.confirm(
                                `Are you sure you want to delete ${cityName} LGA?`
                              );

                              if (shouldDeleteLga === true) {
                                dispatch(deleteStateLGA(id)).then(() =>
                                  window.location.reload()
                                );
                              } else {
                                return;
                              }
                            }}
                            className={Styles.TrashIcon}
                          />
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })
            )}
          </tbody>
        </Table>
      </section>

      <NewLgaModal
        show={openAddModal}
        onHide={() => setopenAddModal(false)}
        stateId={stateId}
        setstateLGA={setstateLGA}
      />
    </React.Fragment>
  );
};

export default ManageState;
