// importing actions

import { LOAD_GENDER_REPORT, LOAD_GRADUATION_REPORT, LOAD_STATE_REPORT, LOAD_TRADE_AREA_REPORT } from '../../types';

// state
const INITIAL_STATE = {
  stateReport: null,
  genderReport: null,
  tradeReport: null,
  gradReport: null
};

// Redux reducer function
const reportReducer = (state = INITIAL_STATE, action) => {
  // Destructuring action
  const { type, payload } = action;

  switch (type) {
    case LOAD_STATE_REPORT:
      return {
        ...state,
        stateReport: payload,
      };
    case LOAD_GENDER_REPORT:
      return {
        ...state,
        genderReport: payload,
      };
    case LOAD_TRADE_AREA_REPORT:
      return {
        ...state,
        tradeReport: payload,
      };
    case LOAD_GRADUATION_REPORT:
      return {
        ...state,
        gradReport: payload,
      };

    default:
      return state;
  }
};

export default reportReducer;
