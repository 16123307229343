import axiosClient from "../../../service/axios";
import {
  CITIES_BY_STATE_ID_URL,
  CITIES_URL,
  GEO_POLITICAL_ZONE_URL,
  PO_STATES_URL,
  STATES_URL,
  UPDATE_PO_STATE_URL,
} from "./locations.endpoints";

/*

@City

*/
const addCity = async (cityName) => {
  try {
    return await axiosClient.post(CITIES_URL, cityName);
  } catch (error) {
    return error;
  }
};

const fetchCities = async () => {
  try {
    return await axiosClient.get(CITIES_URL);
  } catch (error) {
    return error;
  }
};

/*

@ State

*/
const addState = async (details) => {
  try {
    return await axiosClient.post(STATES_URL, details);
  } catch (error) {
    return error;
  }
};

const fetchStates = async () => {
  try {
    return await axiosClient.get(STATES_URL);
  } catch (error) {
    return error;
  }
};

//@get state lga
const fetchStateLGA = async (stateId) => {
  try {
    return await axiosClient.get(CITIES_BY_STATE_ID_URL + "/" + stateId);
  } catch (error) {
    return error;
  }
};

//@delete state lga by id
const deleteStateLGA = async (lgaId) => {
  try {
    return await axiosClient.delete(CITIES_URL + "/" + lgaId);
  } catch (error) {
    return error;
  }
};

/*

@ Geo Political Zone

*/
const addZone = async (details) => {
  try {
    return await axiosClient.post(GEO_POLITICAL_ZONE_URL, details);
  } catch (error) {
    return error;
  }
};

const fetchZones = async () => {
  try {
    return await axiosClient.get(GEO_POLITICAL_ZONE_URL);
  } catch (error) {
    return error;
  }
};

//@load po states
const fetchPOStates = async (poId) => {
  try {
    return await axiosClient.get(PO_STATES_URL + "/" + poId);
  } catch (error) {
    return error;
  }
};

/*

@edit po state

*/
const updatePoState = async (details) => {
  try {
    return await axiosClient.put(UPDATE_PO_STATE_URL + "/" + details.id, {
      stateId: details.stateId,
    });
  } catch (error) {
    return error;
  }
};

const LocationsService = {
  addCity,
  fetchCities,
  addState,
  fetchStates,
  addZone,
  fetchZones,
  fetchStateLGA,
  fetchPOStates,
  updatePoState,
  deleteStateLGA,
};

export default LocationsService;
