import React from "react";
import { Formik } from "formik";
import { Button, Modal, Row, Col, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { editCategory } from "../../../../redux/trade/actions/trade.actions";
import Styles from "./UpdateContactPersonModal.module.css";
import { editContactPerson } from "../../../../redux/admin/actions/admin.actions";
import { updateContactPersonSchema } from "../../../../common/validation/Schema";

export const UpdateContactPersonModal = (props) => {
  const dispatch = useDispatch();
  return (
    <Modal
      {...props}
      size='xxl'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton className='border-0 px-3'></Modal.Header>
      <Modal.Body className='pb-5 pt-0'>
        <div className='mb-3'>
          <p>Edit Contact Person</p>
        </div>
        <Formik
          initialValues={{
            id: props?.record?.id,
            fullName: props?.record?.fullName,
            userName: props?.record?.userName,
            // partnerorganisation: props?.record?.partnerorganisation,
            phoneNumber: props?.record?.phoneNumber,
            // userType: props?.record?.userType,
            email: props?.record?.email,
            // createdAt: props?.record?.createdAt,
          }}
          validationSchema={updateContactPersonSchema}
          onSubmit={(values, { setSubmitting }) => {
            dispatch(
              editContactPerson({ ...props.record, ...values }, setSubmitting)
            ).then((resp) => {
              props.onHide();
            });
          }}
        >
          {({
            handleChange,
            handleSubmit,
            values,
            isSubmitting,
            resetForm,
            errors,
            touched,
            handleBlur,
          }) => (
            <React.Fragment>
              <form onSubmit={handleSubmit}>
                {/* {JSON.stringify(errors, null, 2)} */}
                <Row className='col mb-4'>
                  <Col className='mb-4' sm={12} md={12} xl={12}>
                    <Form.Control
                      type='text'
                      className={`${Styles.formInput} shadow-none`}
                      placeholder='Full Name'
                      name='fullName'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.fullName || ""}
                    />
                    {errors.fullName && touched.fullName ? (
                      <p
                        className={`${Styles.errorMessage} text-danger mt-1 mx-2`}
                      >
                        {errors.fullName}
                      </p>
                    ) : null}
                  </Col>

                  <Col sm={12} md={12} xl={12}>
                    <Form.Control
                      type='text'
                      className={`${Styles.formInput} shadow-none`}
                      placeholder='Username'
                      name='userName'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.userName || ""}
                    />
                    {errors.userName && touched.userName ? (
                      <p
                        className={`${Styles.errorMessage} text-danger mt-1 mx-2`}
                      >
                        {errors.userName}
                      </p>
                    ) : null}
                  </Col>
                </Row>
                <Row className='col mt-4'>
                  <Col className='mb-4' sm={12} md={12} xl={12}>
                    <Form.Control
                      type='text'
                      className={`${Styles.formInput} shadow-none`}
                      placeholder='Email'
                      name='email'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email || ""}
                    />
                    {errors.email && touched.email ? (
                      <p
                        className={`${Styles.errorMessage} text-danger mt-1 mx-2`}
                      >
                        {errors.email}
                      </p>
                    ) : null}
                  </Col>
                  <Col sm={12} md={12} xl={12}>
                    <Form.Control
                      type='text'
                      className={`${Styles.formInput} shadow-none`}
                      placeholder='phoneNumber'
                      name='phoneNumber'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phoneNumber || ""}
                    />
                    {errors.phoneNumber && touched.phoneNumber ? (
                      <p
                        className={`${Styles.errorMessage} text-danger mt-1 mx-2`}
                      >
                        {errors.phoneNumber}
                      </p>
                    ) : null}
                  </Col>
                </Row>
                <section className='d-flex justify-content-center'>
                  <Button
                    className={`shadow-none mt-3 ${Styles.updateBtn}`}
                    disabled={isSubmitting}
                    type='submit'
                  >
                    {isSubmitting ? "Loading" : "UPDATE"}
                  </Button>
                </section>
              </form>
            </React.Fragment>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};
