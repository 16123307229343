import axios from 'axios';
import config from './config';

const axiosClient = axios.create(config);

// interceptor example for expired cookies/token
axiosClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // let res = error.response;
    // if (res && res.status === 401) {
    //   window.location.href = '/';
    // }

    // console.error('Something went wrong. Status Code: ' + res.status);
    return Promise.reject(error);
  }
);

export default axiosClient;
