const config = {
  // baseURL: 'https://meia-project.herokuapp.com', // main branch
  // baseURL: "https://meia-prod.herokuapp.com", // develop branch
  //baseURL: "https://staging.itf-necatsdp.com", // staging branch
   baseURL: "https://api.itf-necatsdp.com/", // a2hosting prod
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};

const token = localStorage.getItem("jwt-token");
if (token) {
  config.headers.authorization = `Bearer ${token}`;
}

export default config;
