import {
  addCategorySuccessToast,
  deleteCategorySuccessToast,
  somethingWentWrongToast,
  updateCategorySuccessToast,
  updatePoCategorySuccessToast,
} from '../../../components/NotificationToast';
import {
  ADD_CATEGORY,
  DELETE_CATEGORY,
  EDIT_CATEGORY,
  LOAD_CATEGORIES,
  LOAD_PO_TRADE_AREAS,
  LOAD_TRADE_AREA_PO,
} from '../../types';
import TradeService from '../services/trade.service';

/*

@Category

@admin only

*/
export const addCategory = (details, formActions) => async (dispatch) => {
  try {
    const response = await TradeService.newCategory(details);
    if (response.status === 200) {
      addCategorySuccessToast();
      dispatch({
        type: ADD_CATEGORY,
        payload: response.data.data,
      });
      formActions.setSubmitting(false);
      formActions.resetForm({});
      return response;
    }
    return response;
  } catch (error) {
    formActions.setSubmitting(false);
    somethingWentWrongToast();
    return error;
  }
};

/*

@ delete category
*/

export const deleteCategoryByID = (id) => async (dispatch) => {
  try {
    const response = await TradeService.deleteCategories(id);
    dispatch({
      type: DELETE_CATEGORY,
      payload: id,
    });
    deleteCategorySuccessToast();

    return response;
  } catch (error) {
    somethingWentWrongToast();
    return error;
  }
};

/*

@edit category

*/

export const editCategory = (details, setSubmitting) => async (dispatch) => {
  try {
    const response = await TradeService.updateCategory(details);
    dispatch({
      type: EDIT_CATEGORY,
      payload: details,
    });

    updateCategorySuccessToast();
    setSubmitting(false);

    return response;
  } catch (error) {
    setSubmitting(false);
    somethingWentWrongToast();

    return error;
  }
};

/*

@Load categories created by admin
@admin only

*/

export const loadCategories = () => async (dispatch) => {
  try {
    const response = await TradeService.fetchCategories();
    if (response.status === 200) {
      dispatch({
        type: LOAD_CATEGORIES,
        payload: response.data.data,
      });
      return response;
    }
    return response;
  } catch (error) {
    somethingWentWrongToast();
    return error;
  }
};

/*

@Get PO assigned Category

*/

export const loadPOTradeAreas = (poId) => async (dispatch) => {
  try {
    const response = await TradeService.fetchPOTradeAreas(poId);
    if (response.status === 200) {
      dispatch({
        type: LOAD_PO_TRADE_AREAS,
        payload: response?.data?.category,
      });
      return response;
    }
    return response;
  } catch (error) {
    somethingWentWrongToast();
    return error;
  }
};




/* 

@Update PO category

*/

export const editPoCategory = (details, setSubmitting) => async (dispatch) => {
  try {
    const response = await TradeService.updatePoCategory(details);
    updatePoCategorySuccessToast();
    setSubmitting(false);

    return response;
  } catch (error) {
    setSubmitting(false);
    somethingWentWrongToast();

    return error;
  }
};

/*

@Get PO in a particular trade area

*/

export const loadTradeAreaPo = (id) => async (dispatch) => {
  try {
    const response = await TradeService.fetchTradeAreasPo(id);
    if (response.status === 200) {
      dispatch({
        type: LOAD_TRADE_AREA_PO,
        payload: response.data,
      });
      return response;
    }
    return response;
  } catch (error) {
    somethingWentWrongToast();
    return error;
  }
};
