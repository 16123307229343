import {
  lgaDeletedSuccessfully,
  somethingWentWrongToast,
} from "../../../components/NotificationToast";
import {
  LOAD_CITIES,
  LOAD_PO_STATES,
  LOAD_STATES,
  LOAD_STATE_LGA,
  LOAD_ZONES,
} from "../../types";

import LocationsService from "../services/locations.service";

/*

@Cities

*/

export const newCity =
  (cityName, stateId, setSubmitting) => async (dispatch) => {
    const payload = {
      cityName: cityName,
      stateId,
    };

    try {
      const response = await LocationsService.addCity(payload);
      setSubmitting(false);
      return response;
    } catch (error) {
      console.log(error);
      setSubmitting(false);

      return error;
    }
  };

export const loadCities = () => async (dispatch) => {
  try {
    const response = await LocationsService.fetchCities();
    dispatch({
      type: LOAD_CITIES,
      payload: response.data.data,
    });
  } catch (error) {
    somethingWentWrongToast();
    return error;
  }
};

export const deleteStateLGA = (lgaId) => (dispatch) => {
  try {
    const response = LocationsService.deleteStateLGA(lgaId);
    lgaDeletedSuccessfully();
    return response;
  } catch (error) {
    somethingWentWrongToast();
    return error;
  }
};

/*

@State

*/

export const newState = (details, setSubmitting) => async (dispatch) => {
  try {
    const response = await LocationsService.addState(details);
  } catch (error) {
    console.log(error);

    return error;
  }
};

export const loadStates = () => async (dispatch) => {
  try {
    const response = await LocationsService.fetchStates();
    dispatch({
      type: LOAD_STATES,
      payload: response.data.data,
    });

    return response;
  } catch (error) {
    somethingWentWrongToast();
    return error;
  }
};

//@load state lga
export const loadStateLGA = (stateId) => async (dispatch) => {
  try {
    const response = await LocationsService.fetchStateLGA(stateId);
    if (response.status === 200) {
      dispatch({
        type: LOAD_STATE_LGA,
        payload: response.data.data,
      });
    }
    return response;
  } catch (error) {
    somethingWentWrongToast();
    return error;
  }
};

/*

@Geo Political Zones


*/

export const newZone = (details, setSubmitting) => async (dispatch) => {
  try {
    const response = await LocationsService.addZone(details);
  } catch (error) {
    console.log(error);

    return error;
  }
};

//@load all zones
export const loadZones = () => async (dispatch) => {
  try {
    const response = await LocationsService.fetchZones();
    dispatch({
      type: LOAD_ZONES,
      payload: response.data.data,
    });
  } catch (error) {
    somethingWentWrongToast();
  }
};

//@load po states
export const loadPOStates = (poId) => async (dispatch) => {
  try {
    const response = await LocationsService.fetchPOStates(poId);
    dispatch({
      type: LOAD_PO_STATES,
      payload: response.data.state,
    });
    return response;
  } catch (error) {
    somethingWentWrongToast();
    return error;
  }
};

/* 

@Update PO state

*/

export const editPoState = (details, setSubmitting) => async (dispatch) => {
  try {
    const response = await LocationsService.updatePoState(details);
    // updatePoCategorySuccessToast();
    setSubmitting(false);

    return response;
  } catch (error) {
    setSubmitting(false);
    somethingWentWrongToast();

    return error;
  }
};
