/*
 *@format: route: navbar-heading
 */

const routeSwapTitle = {
  '/admin': 'Dashboard',
  '/admin/partnerorg': 'Partner Organizations',
  '/admin/settings': 'Settings',
  '/admin/settings/profile': 'Profile',
  '/admin/userpermissions': 'User Permissions',
  '/admin/userpermissions/add': 'User Permissions',
  '/admin/support': 'Support',
  '/admin/trainees': 'Trainees',
  '/admin/trainees/add': 'Trainees',
  '/admin/tradeareas/view': 'Trade Areas',
  '/admin/tradeareas/viewcategory': 'Trade Areas',
  '/admin/tradeareas/add': 'Trade Areas',
  '/admin/tradeareas/addcategory': 'Trade Areas',
  '/admin/tradeareas': 'Trade Areas',
  '/admin/contactperson': 'Contact Person',
  '/admin/contactperson/add': 'Contact Person',
  '/admin/contactperson/:id': 'Contact Person',
};

export default routeSwapTitle;
