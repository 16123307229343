import axiosClient from "../../../service/axios";
import {
  ADD_BENEFICIARY_IN_BULK_URL,
  BENEFICIARY_URL,
  FETCH_PO_AUDIT_TRAIL_URL,
  FILE_UPLOAD_URL,
  GET_TRAINEE_EMPLOYMENT_INFO_URL,
  PARTNER_ORG_TRAINEES,
  PO_DASHBOARD_COUNT,
  PO_TRAINING_BATCH_URL,
  TRAINEE_CATEGORY_URL,
  TRAINEE_EMPLOYMENT_URL,
  TRAINEE_GRADUATION_URL,
  TRAINING_BATCH_URL,
} from "./partnerorgs.endpoints";

/*
@get po dashboard count

*/
const fetchPODashboardCount = async () => {
  try {
    return await axiosClient.get(PO_DASHBOARD_COUNT);
  } catch (error) {
    return error;
  }
};

//@get system activities by user
const fetchPOAuditTrail = async () => {
  try {
    return await axiosClient.get(FETCH_PO_AUDIT_TRAIL_URL);
  } catch (error) {
    return error;
  }
};

//@new trainee
const newTrainee = async (details) => {
  try {
    return await axiosClient.post(BENEFICIARY_URL, details);
  } catch (error) {
    return error;
  }
};

//@excel po profile upload
const bulkTraineeUpload = async (file) => {
  try {
    //upload file
    const response = await axiosClient.post(ADD_BENEFICIARY_IN_BULK_URL, file);

    return response;
  } catch (error) {
    return error;
  }
};

//@get single contact person by ID
const getTraineeByID = async (id) => {
  try {
    return await axiosClient.get(BENEFICIARY_URL + "/" + id);
  } catch (error) {
    return error;
  }
};

/*
@edit trainee record

*/

const updateTraineeRecord = async (details) => {
  try {
    return await axiosClient.put(BENEFICIARY_URL + "/" + details.id, details);
  } catch (error) {
    return error;
  }
};

//@update trainee employment detail
const updateTraineeGraduation = async (details) => {
  try {
    return await axiosClient.post(TRAINEE_GRADUATION_URL, details);
  } catch (error) {
    return error;
  }
};

//@update trainee category detail
const updateTraineeCategory = async (newCategory, traineeId) => {
  try {
    return await axiosClient.put(TRAINEE_CATEGORY_URL + traineeId, newCategory);
  } catch (error) {
    return error;
  }
};

//@update trainee employment detail
const updateTraineeEmployment = async (details) => {
  try {
    return await axiosClient.post(TRAINEE_EMPLOYMENT_URL, details);
  } catch (error) {
    return error;
  }
};

//@get trainee employment info
const fetchTraineeEmploymentInfo = async (traineeId) => {
  try {
    return await axiosClient.get(
      GET_TRAINEE_EMPLOYMENT_INFO_URL + "/" + traineeId
    );
  } catch (error) {
    return error;
  }
};

// @delete trainee
const deleteTrainee = async (id) => {
  try {
    return await axiosClient.delete(BENEFICIARY_URL + "/" + id);
  } catch (error) {
    return error;
  }
};

// @new batch
const createTradeBatch = async (details) => {
  try {
    return await axiosClient.post(TRAINING_BATCH_URL, details);
  } catch (error) {
    return error;
  }
};

/*
@edit trade batch

*/

const updateTradeBatch = async (details) => {
  try {
    return await axiosClient.put(
      TRAINING_BATCH_URL + "/" + details.id,
      details
    );
  } catch (error) {
    return error;
  }
};

// @delete trade batch
const deleteBatch = async (id) => {
  try {
    return await axiosClient.delete(TRAINING_BATCH_URL + "/" + id);
  } catch (error) {
    return error;
  }
};

// @get po batch
const fetchPOBatches = async () => {
  try {
    return axiosClient.get(PO_TRAINING_BATCH_URL);
  } catch (error) {
    return error;
  }
};

//@get po trainees
const fetchPOTrainees = async (pageNumber, size) => {
  try {
    pageNumber = pageNumber === undefined ? 1 : pageNumber;
    size = size === undefined ? 500 : size;
    return axiosClient.get(
      `${PARTNER_ORG_TRAINEES}?page=${pageNumber}&size=${size}`
    );
  } catch (error) {
    return error;
  }
};

//@profile image upload
const uploadTraineeProfileImage = async (file, traineeId) => {
  try {
    //upload file
    const response = await axiosClient.post(FILE_UPLOAD_URL, file);

    if (response.status === 200) {
      // update user obj with img path
      const response2 = await axiosClient.put(
        BENEFICIARY_URL + "/" + traineeId,
        { profileImage: response.data }
      );
      return response2;
    }
  } catch (error) {
    return error;
  }
};

//@Cv upload
const uploadTraineeCV = async (file, traineeId) => {
  try {
    //upload file
    const response = await axiosClient.post(FILE_UPLOAD_URL, file);

    if (response.status === 200) {
      // update user obj with cv path
      const response2 = await axiosClient.put(
        BENEFICIARY_URL + "/" + traineeId,
        { curriculumVitae: response.data }
      );
      return response2;
    }
  } catch (error) {
    return error;
  }
};

const POService = {
  newTrainee,
  bulkTraineeUpload,
  fetchPOBatches,
  fetchPOTrainees,
  createTradeBatch,
  updateTradeBatch,
  deleteBatch,
  deleteTrainee,
  getTraineeByID,
  updateTraineeRecord,
  fetchPODashboardCount,
  updateTraineeEmployment,
  uploadTraineeProfileImage,
  uploadTraineeCV,
  fetchTraineeEmploymentInfo,
  fetchPOAuditTrail,
  updateTraineeGraduation,
  updateTraineeCategory,
};

export default POService;
