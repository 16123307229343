import React from "react";
import { ErrorMessage, Formik } from "formik";
import { Button, Modal, Row, Col, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Styles from "./UpdateTraineeEmploymentModal.module.css";
import {
  editTradeBatch,
  editTraineeRecord,
  loadTraineeById,
  updateTraineeEmployment,
} from "../../../../redux/partnerOrgs/actions/partnerorgs.actions";
import { SelectField } from "../../../../components/Form/SelectField";
import { loadTraineeProfile } from "../../../../redux/user/actions/user.actions";

export const UpdateTraineeEmploymentModal = (props) => {
  const dispatch = useDispatch();
  const employmentStatusOptions = [
    { value: "employed", label: "Employed" },
    { value: "unemployed", label: "Unemployed" },
    { value: "self employed", label: "Self employed" },
  ];
  return (
    <Modal
      {...props}
      size='xxl'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton className='border-0 px-3'></Modal.Header>
      <Modal.Body className='pb-5 pt-0'>
        <div className='mb-3'>
          <p className={Styles.heading}>
            Updating Employment Record of :{" "}
            <span className='text-warning'>{props?.record?.firstName}</span>
          </p>
        </div>
        <Formik
          initialValues={{
            // id: props?.record?.id,
            organisationName: props?.record?.employ?.organisationName,
            organisationAddress: props?.record?.employ?.organisationAddress,
            yearEmployed: props?.record?.employ?.yearEmployed,
            employmentStatus: {
              value: props?.record?.employmentStatus,
              label: props?.record?.employmentStatus,
            },
          }}
          validate={(values) => {
            const errors = {};

            if (!values.employmentStatus) {
              errors.employmentStatus = "Required";
            }
            if (
              values.employmentStatus.value === "employed" &&
              !values.organisationName
            ) {
              errors.organisationName = "Required";
            }
            if (
              values.employmentStatus.value === "employed" &&
              !values.yearEmployed
            ) {
              errors.yearEmployed = "Required";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            // //@employment status
            // const newEmploymentStatus = values.employmentStatus.value;
            // //@employ is an object
            // const employ = {
            //   ...props.record.employ,
            //   organisationAddress: values.organisationAddress,
            //   organisationName: values.organisationName,
            //   yearEmployed: values.yearEmployed,
            // };
            // //@new record
            // const newRecord = {
            //   ...props.record,
            //   employ,
            //   employmentStatus: newEmploymentStatus,
            // };

            values.employmentStatus = values.employmentStatus.value;
            values.beneficiaryId = props?.record?.id;
            dispatch(updateTraineeEmployment(values, setSubmitting)).then(
              (resp) => {
                // po editing record
                dispatch(loadTraineeById(props?.record?.id));
                // training editing record
                props.trainingediting && dispatch(loadTraineeProfile());
                // hide modal
                props.onHide();
              }
            );
          }}
        >
          {({
            handleChange,
            handleSubmit,
            values,
            isSubmitting,
            resetForm,
            setFieldValue,
            handleBlur,
            touched,
            errors,
          }) => (
            <React.Fragment>
              <form onSubmit={handleSubmit}>
                {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}

                <Row className='col'>
                  <Col className='mb-4' sm={12} md={12} xl={12}>
                    <SelectField
                      className={`${Styles.formInput} shadow-none form-control`}
                      placeholder='Employment Status'
                      name='employmentStatus'
                      options={employmentStatusOptions}
                      onChange={setFieldValue}
                      value={values.employmentStatus}
                      onBlur={handleBlur}
                    />

                    {errors.employmentStatus ? (
                      <p className='text-danger mt-1 mx-2'>
                        {errors.employmentStatus}
                      </p>
                    ) : null}
                  </Col>
                  <Col sm={12} md={12} xl={12}>
                    <Form.Control
                      type='text'
                      className={`${Styles.formInput} shadow-none`}
                      placeholder='Employer Name'
                      name='organisationName'
                      onChange={handleChange}
                      value={values.organisationName || ""}
                    />
                    {errors.organisationName ? (
                      <p className='text-danger mt-1 mx-2'>
                        {errors.organisationName}
                      </p>
                    ) : null}
                  </Col>
                </Row>
                <Row className='col mt-4'>
                  <Col sm={12} md={12} xl={12} className='mb-4'>
                    <Form.Control
                      type='text'
                      className={`${Styles.formInput} shadow-none`}
                      placeholder='Employer Address'
                      name='organisationAddress'
                      onChange={handleChange}
                      value={values.organisationAddress || ""}
                    />
                  </Col>
                  <Col sm={12} md={12} xl={12}>
                    <label className={`${Styles.label} mb-3`}>
                      Employment Date
                    </label>
                    <Form.Control
                      type='date'
                      className={`${Styles.formInput} shadow-none`}
                      name='yearEmployed'
                      onChange={handleChange}
                      value={values.yearEmployed || ""}
                    />
                    {errors.yearEmployed ? (
                      <p className='text-danger mt-1 mx-2'>
                        {errors.yearEmployed}
                      </p>
                    ) : null}
                  </Col>
                </Row>
                <section className='mt-3 d-flex justify-content-center'>
                  <Button
                    className={`shadow-none mt-3 ${Styles.updateBtn}`}
                    disabled={isSubmitting}
                    type='submit'
                  >
                    {isSubmitting ? "Loading" : "UPDATE"}
                  </Button>
                </section>
              </form>
            </React.Fragment>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};
