import React from "react";
import styles from "./Info.module.css";

const FeaturedInfo = ({ bgColor, color, cardText, value }) => {
  return (
    <>
      <div className={styles.informationContainer}>
        <div
          style={{ backgroundColor: bgColor, color: color }}
          className={styles.noOfTrade}
        >
          <p className={styles.title}>{cardText}</p>
          <p className={styles.value}>{value}</p>
        </div>
      </div>
    </>
  );
};

export default FeaturedInfo;
