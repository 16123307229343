import React from "react";
import { Modal } from "react-bootstrap";
import config from "../../../../service/config";

export const ViewCvModal = (props) => {
  console.log(props);
  return (
    <Modal
      {...props}
      size='xl'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton className='border-0 px-3'></Modal.Header>
      <Modal.Body className='pb-5 pt-0'>
        <embed
          src={`${config.baseURL}/${props.curriculumvitaelink}`}
          frameBorder='0'
          width='100%'
          height='500px'
        ></embed>
      </Modal.Body>
      <Modal.Footer>
        <a
          href={`${config.baseURL}/${props.curriculumvitaelink}`}
          className='text-decoration-none text-blue'
          target='_blank'
          rel='noopener noreferrer'
        >
          Open CV in New Tab
        </a>
      </Modal.Footer>
    </Modal>
  );
};
