/* 
@example 
    
    export const ENDPOINT_ACTION_NAME = "/endpoint-url"
*/

//@po
export const PO_URL = '/participating-organisation/';

export const PO_BULK_URL = '/participating-organisation/import/csv';

//@create/User
export const CONTACT_PERSON_URL = '/users/';
//@get contact persons
export const FETCH_CONTACT_PERSON_URL = '/users/contactperson';

//@beneficiaries
export const BENEFICIARIES_URL = '/beneficiaries/';

//@dashboard count
export const DASHBOARD_COUNT_URL = '/dashboard';

//@Reports
export const REPORT_URL = '/beneficiaries';

// @audit trail
export const FETCH_ADMIN_AUDIT_TRAIL_URL = '/audit-trail/admin';
