import React from "react";
import { Formik } from "formik";
import { Button, Modal, Row, Col, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Styles from "./new-lga-modal.module.css";
import {
  loadStateLGA,
  newCity,
} from "../../../../redux/locations/actions/locations.actions";
import { lgaAddedSuccessfully } from "../../../../components/NotificationToast";

export const NewLgaModal = (props) => {
  const dispatch = useDispatch();
  const { setstateLGA } = props;
  const stateId = props?.stateId;
  return (
    <Modal
      {...props}
      size='xxl'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton className='border-0 px-3'></Modal.Header>
      <Modal.Body className='pb-5 pt-0'>
        <div className='mb-3'>
          <p>Add New LGA</p>
        </div>
        <Formik
          initialValues={{
            cityName: "",
          }}
          validate={(values) => {
            const errors = {};
            if (!values.cityName) {
              errors.cityName = "Required";
              errors.batchDescriptiom = "Required";
            }
            return errors;
          }}
          onSubmit={(values, actions) => {
            console.log(values);
            dispatch(
              newCity(values.cityName, stateId, actions.setSubmitting)
            ).then((res) => {
              setstateLGA((prevState) => [...prevState, res?.data?.data]);

              lgaAddedSuccessfully();
            });
          }}
        >
          {({
            handleChange,
            handleSubmit,
            values,
            isSubmitting,
            resetForm,
          }) => (
            <React.Fragment>
              <form onSubmit={handleSubmit}>
                <Row className='col'>
                  <Col className='mb-4' sm={12} md={12} xl={12}>
                    <Form.Control
                      type='text'
                      className={`${Styles.formInput} text-capitalize shadow-none`}
                      placeholder='Kwara Local Government Area'
                      name='cityName'
                      onChange={handleChange}
                      value={values.cityName || ""}
                    />
                  </Col>
                </Row>

                <section className='d-flex justify-content-center'>
                  <Button
                    className={`shadow-none mt-3 ${Styles.saveBtn}`}
                    disabled={isSubmitting}
                    type='submit'
                  >
                    {isSubmitting ? "Loading" : "ADD"}
                  </Button>
                </section>
              </form>
            </React.Fragment>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};
