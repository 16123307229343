// yup validation
import * as Yup from "yup";

// regex for email
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

// // Signup Schema
// export const signUpSchema = Yup.object().shape({
//   companyName: Yup.string().max(15, 'Workspace Name must be less than 16').required('Workspace Name is required'),
//   email: Yup.string().required('Email is required'),
//   password: Yup.string().min(4, 'Password must be at least 7 characters').required('Password is required'),
//   confirmPassword: Yup.string().required('Please confirm password').oneOf([Yup.ref('password')], 'Password Mismatch'),
//   termsOfService : Yup.bool().oneOf([true], 'Please review and accept Terms and Condition')
// })

// Add Participating Organization
export const participatingOrganizationSchema = Yup.object().shape({
  necaId: Yup.string().required("This field is required"),
  organisationName: Yup.string()
    .min(4, "Name of oragnization too short")
    .required("This field is required"),
  categories: Yup.array()
    .min(1, "This field is required")
    .required("This field is required"),
  phoneNumber: Yup.string()
    .required("This field is required")
    .matches(phoneRegExp, "Phone number is not valid"),
  address: Yup.string()
    .min(5, "Address too short")
    .required("This field is required"),
  emailAddress: Yup.string()
    .email("Invalid email address")
    .required("This field is required"),
  stateId: Yup.array()
    .min(1, "This field is required")
    .required("This field is required"),
  duration: Yup.object().shape({
    label: Yup.string().required("Duration is required").nullable(),
    value: Yup.string().required("Duration is required").nullable(),
  }),
});

// Update Participating Organization
export const updateParticipatingOrganizationSchema = Yup.object().shape({
  organisationName: Yup.string()
    .min(4, "Name of oragnization too short")
    .required("This field is required"),
  phoneNumber: Yup.string()
    .required("This field is required")
    .matches(phoneRegExp, "Phone number is not valid"),
  address: Yup.string()
    .min(5, "Address too short")
    .required("This field is required"),
  emailAddress: Yup.string()
    .email("Invalid email address")
    .required("This field is required"),
  // stateId: Yup.array().min(1, "This field is required").required("This field is required"),
});

// Update Participating Organization Category
export const updateParticipatingCategorySchema = Yup.object().shape({
  categories: Yup.array()
    .min(1, "This field is required")
    .required("This field is required"),
});

// Update Participating State
export const updateParticipatingStateSchema = Yup.object().shape({
  stateId: Yup.array()
    .min(1, "This field is required")
    .required("This field is required"),
});

// Add Category
export const categorySchema = Yup.object().shape({
  categoryName: Yup.string()
    .min(4, "Name too short")
    .max(255, "Name too long")
    .required("Name is required"),
  categoryDescription: Yup.string()
    .min(4, "Name too short")
    .max(255, "Description too long")
    .required("Description is required"),
  duration: Yup.object().shape({
    label: Yup.string().required("Duration is required").nullable(),
    value: Yup.string().required("Duration is required").nullable(),
  }),
});

//change password
export const changePasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(7, "Password must be at least 7 characters")
    .required("Password is required"),
  cPassword: Yup.string()
    .required("Please confirm password")
    .oneOf([Yup.ref("password")], "Password Mismatch"),
});

//new contact person
export const newContactPersonSchema = Yup.object().shape({
  fullName: Yup.string().min("5").required("This field is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("This field is required"),
  phoneNumber: Yup.string()
    .required("This field is required")
    .matches(phoneRegExp, "Phone number is not valid"),
  password: Yup.string()
    .min(7, "Password must be at least 7 characters")
    .required("Password is required"),
  userName: Yup.string()
    .min(5, "Username too short")
    .required("This field is required"),
  partnerorganisationId: Yup.object().shape({
    label: Yup.string().required("This field is required").nullable(),
    value: Yup.string().required("This field is required").nullable(),
  }),
});

//update contact person
export const updateContactPersonSchema = Yup.object().shape({
  fullName: Yup.string().min("5").required("This field is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("This field is required"),
  phoneNumber: Yup.string()
    .required("This field is required")
    .matches(phoneRegExp, "Phone number is not valid"),
  userName: Yup.string()
    .min(5, "Username too short")
    .required("This field is required"),
});

//new trainee
export const newTraineeSchema = Yup.object().shape({
  firstName: Yup.string().required("This field is required"),
  lastName: Yup.string().required("This field is required"),
  // middleName: Yup.string().required("This field is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("This field is required"),
  phoneNumber: Yup.string()
    .required("This field is required")
    .matches(phoneRegExp, "Phone number is not valid"),
  gender: Yup.object().shape({
    label: Yup.string().required("This field is required").nullable(),
    value: Yup.string().required("This field is required").nullable(),
  }),
  // stateOfOrigin: Yup.string().required("This field is required"),

  localGovernmentOfOrigin: Yup.object().shape({
    label: Yup.string().required("This field is required").nullable(),
    value: Yup.string().required("This field is required").nullable(),
  }),
  stateOfResidence: Yup.object().shape({
    label: Yup.string().required("This field is required").nullable(),
    value: Yup.string().required("This field is required").nullable(),
  }),
  highestQualification: Yup.object().shape({
    label: Yup.string().required("This field is required").nullable(),
    value: Yup.string().required("This field is required").nullable(),
  }),

  categoryId: Yup.array()
    .min(1, "This field is required")
    .required("This field is required"),
  trainingbatchId: Yup.object().shape({
    label: Yup.string().required("This field is required").nullable(),
    value: Yup.string().required("This field is required").nullable(),
  }),
  dateOfBirth: Yup.date().required("This field is required"),
});

//update trainee record
export const updateTraineeSchema = Yup.object().shape({
  firstName: Yup.string().required("This field is required"),
  lastName: Yup.string().required("This field is required"),
  // middleName: Yup.string().required("This field is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("This field is required"),
  phoneNumber: Yup.string()
    .required("This field is required")
    .matches(phoneRegExp, "Phone number is not valid"),
  gender: Yup.object().shape({
    label: Yup.string().required("This field is required").nullable(),
    value: Yup.string().required("This field is required").nullable(),
  }),
  // stateOfOrigin: Yup.string().required("This field is required"),

  localGovernmentOfOrigin: Yup.object().shape({
    label: Yup.string().required("This field is required").nullable(),
    value: Yup.string().required("This field is required").nullable(),
  }),
  stateOfResidence: Yup.object().shape({
    label: Yup.string().required("This field is required").nullable(),
    value: Yup.string().required("This field is required").nullable(),
  }),
  highestQualification: Yup.object().shape({
    label: Yup.string().required("This field is required").nullable(),
    value: Yup.string().required("This field is required").nullable(),
  }),
  dateOfBirth: Yup.date().required("This field is required"),
});
