/*
 *@format: route: navbar-heading
 */

const routeSwapTitle = {
  '/podashboard': 'Dashboard',
  '/podashboard/settings': 'Settings',
  '/podashboard/settings/users-permission': 'Settings',
  '/podashboard/support': 'Support',
  '/podashboard/trainees': 'Trainees',
  '/podashboard/trainees/add': 'Trainees',
  '/podashboard/trainees/view': 'Trainees',
};

export default routeSwapTitle;
