import React from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Styles from './ExportCsv.module.css';
import { FiUpload } from 'react-icons/fi';

export const ExportCsv = ({ table, fileName }) => {
  return (
    <div className={`${Styles.actionBtns} ${Styles.actionBtn1} me-3`}>
      <ReactHTMLTableToExcel
        className={Styles.csv}
        table={table}
        filename={fileName}
        sheet='tablexls'
        buttonText='Export To Excel'
      />

      <FiUpload className='ms-3' style={{ fontSize: '16px' }} />
    </div>
  );
};
