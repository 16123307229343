import React, { useEffect, useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import Styles from "./PartnerOrgs.module.css";
import { Button, Col, Row, Table } from "react-bootstrap";
import Info from "../../../components/TA_InfoCard/Info";

// Importing Icons
import { BsEye, BsSearch } from "react-icons/bs";
import { BiPencil, BiTrash } from "react-icons/bi";
import { CgArrowsExchangeAltV } from "react-icons/cg";
import { FiPrinter, FiUpload } from "react-icons/fi";
import { ConfirmationModal } from "../../../components/Modal/ConfirmationModal";
import { Link } from "react-router-dom";
import {
  loadAllContactPerson,
  loadPartnerOrgs,
  deleteContactPersonByID,
} from "../../../redux/admin/actions/admin.actions";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import dateFormat from "../../../helper/DateFormatter";
import { ExportCsv } from "../../../components/Csv/ExportCsv";

import { UpdateContactPersonModal } from "./UpdateContactPersonModal/UpdateContactPersonModal";

import { LinearLoader } from "./../../../components/Loader/LinearLoader";

// Excel import
import { CSVLink } from "react-csv";
import Desc from "../../../components/Desc";
import ReactPaginate from "react-paginate";

const ContactPerson = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [openConfirmationModal, setopenConfirmationModal] = useState(false);
  const [openEditModal, setopenEditModal] = useState(false);
  const [contactToDelete, setContactToDelete] = useState(null);
  const [contactToEdit, setcontactToEdit] = useState(null);
  const { allContactPerson, pos } = useSelector((state) => state.adminReducer);
  const [loading, setloading] = useState(false);

  const [tableAction, setTableAction] = useState(false);

  // pagination
  const [pageNumber, setPageNumber] = useState(0);
  const size = 10;
  const pagesVisited = pageNumber * size;
  const pageCount = Math.ceil(allContactPerson?.length / size);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  useEffect(() => {
    setloading(true);

    dispatch(loadAllContactPerson()).then(() => {
      setloading(false);
      // setTableAction(false);
    });
    dispatch(loadPartnerOrgs());
  }, [dispatch]);

  // delete function
  const handleDelete = () => {
    dispatch(deleteContactPersonByID(contactToDelete)).then((res) => {
      setopenConfirmationModal(false);
      dispatch(loadAllContactPerson());
    });
  };

  useEffect(() => {
    setData(
      allContactPerson?.filter((cp) => {
        return Object.values(cp).some((name) =>
          String(name).toLowerCase().includes(search.toLowerCase())
        );
      })
    );
  }, [allContactPerson, search]);

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const shortenUserType = {
    partnerOrganisation: "PO",
    admin: "admin",
  };

  // Print function
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle:
      "@page { size: auto; margin: 10mm; } @media print { body { -webkit-print-color-adjust: exact; padding: 40px !important; } }",
  });
  function delay(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  // Excel dataset
  const headers = [
    { label: "SN", key: "sn" },
    { label: "DATE CREATED", key: "datecreated" },
    { label: "FULL NAME", key: "fullname" },
    { label: "USERNAME", key: "username" },
    { label: "PHONE NO", key: "phoneno" },
    { label: "ORGANIZATION", key: "organization" },
    { label: "EMAIL", key: "email" },
  ];

  const values = allContactPerson?.map((contact, index) => ({
    sn: index + 1,
    datecreated: dateFormat.DateYearMonthDay(contact?.createdAt),
    fullname: contact?.fullName,
    username: contact?.userName,
    phoneno: contact?.phoneNumber,
    organization: contact?.partnerorganisation?.organisationName,
    email: contact?.email,
  }));

  return (
    <>
      <Desc title={`Contact Persons`} />
      <React.Fragment>
        <div className={Styles.mainDashboardContent}>
          <nav
            aria-label='breadcrumb'
            style={{ maxWidth: "95%", margin: "auto" }}
          >
            <ol className='breadcrumb mt-3'>
              <li
                className={`${Styles.breadCrumb} breadcrumb-item`}
                aria-current='page'
              >
                Contact Person
              </li>
            </ol>
          </nav>
          <div className={Styles.tradeAreaCardCont}>
            <Info
              bgColor='#c4e4ff'
              color='#333333'
              cardText='NO OF PARTNER ORGANIZATIONS'
              value={pos?.length || "-"}
            />
            <Info
              bgColor='#caf7d1'
              color='#333333'
              cardText='NO OF CONTACT PERSON'
              value={allContactPerson?.length - 1 || "-"}
            />
          </div>
          <section className={`${Styles.ActionContainer} bg-white`}>
            <section style={{ maxWidth: "95%", margin: "auto" }}>
              <Row className={`${Styles.action}`}>
                <Col className='align-items-center' sm={12} md={6}>
                  <div className='float-start'>
                    <CSVLink
                      headers={headers}
                      filename={`Contact Persons ${dateFormat.DateYearMonthDay(
                        Date.now()
                      )} ${dateFormat.TimeOnly()}`}
                      data={!values ? "loading..." : values}
                    >
                      <Button
                        className={`${Styles.actionBtns} ${Styles.actionBtn1} me-3`}
                      >
                        Export To Excel
                        <FiUpload
                          className='ms-3'
                          style={{ fontSize: "16px" }}
                        />
                      </Button>
                    </CSVLink>
                  </div>
                  <div>
                    <Button
                      className={`${Styles.actionBtns} ${Styles.actionBtn2} me-3`}
                      onClick={async () => {
                        setTableAction(true);
                        await delay(1000);
                        handlePrint();

                        setTableAction(false);
                      }}
                    >
                      Print
                      <FiPrinter
                        className='ms-3'
                        style={{ fontSize: "16px" }}
                      />
                    </Button>
                  </div>
                </Col>
                <Col className='text-center mt-4 mt-md-0' sm={12} md={6}>
                  <Link to='add'>
                    <Button
                      className={`${Styles.actionBtns} ${Styles.actionBtn3} float-start float-md-end`}
                    >
                      ADD NEW
                    </Button>
                  </Link>
                </Col>
              </Row>
            </section>
          </section>

          {/* LOADER */}
          {loading && <LinearLoader />}
          <section className='bg-white py-3 mt-5'>
            <section>
              <form
                style={{ maxWidth: "95%", margin: "auto" }}
                className={`form-group pb-3 ${Styles.hasSearch}`}
              >
                <BsSearch className={`${Styles.formControlSearchIcon}`} />
                <input
                  type='text'
                  className={`form-control form-control-sm shadow-none ${Styles.SearchBox}`}
                  placeholder='Search'
                  aria-label='Search'
                  onChange={handleChange}
                />
              </form>

              <section style={{ maxWidth: "95%", margin: "auto" }}>
                <Table borderless responsive id='contact' ref={componentRef}>
                  <thead>
                    <tr
                      className={
                        tableAction ? Styles["trAction"] : Styles["thAction"]
                      }
                    >
                      <th colSpan='3'>
                        <div>
                          <p className='text-underline'>
                            CONTACT PERSONS :{" "}
                            {dateFormat.DateYearMonthDay(Date.now())}{" "}
                            {dateFormat.TimeOnly()}{" "}
                          </p>
                        </div>
                      </th>
                    </tr>
                    <tr className={Styles.TableHeadRow}>
                      <th>SN</th>
                      <th>
                        <div
                          className='d-flex align-items-center align-items-center'
                          role='button'
                        >
                          DATE CREATED
                        </div>
                      </th>
                      <th>
                        <div
                          className='d-flex align-items-center align-items-center'
                          role='button'
                        >
                          FULL NAME
                        </div>
                      </th>
                      <th>USERNAME</th>
                      <th>ORGANIZATION</th>
                      <th>EMAIL</th>
                      <th
                        className={
                          tableAction ? Styles["thAction"] : Styles["trAction"]
                        }
                      >
                        ACTIONS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loading && data?.length === 0 ? (
                      <tr>
                        <td
                          colSpan='7'
                          className='text-center py-4 text-capitalize'
                        >
                          Contact person list is empty.
                        </td>
                      </tr>
                    ) : (
                      data
                        ?.filter(
                          (contactperson) => contactperson.userType !== "admin"
                        )
                        ?.slice(pagesVisited, pagesVisited + size)
                        ?.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
                        .map(
                          (
                            {
                              id,
                              fullName,
                              partnerorganisation,
                              phoneNumber,
                              userName,
                              userType,
                              email,
                              createdAt,
                            },
                            index
                          ) => {
                            return (
                              <React.Fragment key={id}>
                                <tr className={Styles.TableBodyRow}>
                                  {/* <td>
                              <Form.Check
                                type='checkbox'
                                className={Styles.TicketCheckBox}
                              />
                            </td> */}
                                  <td>{pageNumber * size + index + 1}</td>
                                  <td>{dateFormat.YearAlone(createdAt)}</td>
                                  <td className='text-capitalize'>
                                    {fullName}
                                  </td>
                                  <td>{userName}</td>
                                  {/* <td>{phoneNumber}</td> */}
                                  <td className='text-capitalize'>
                                    {partnerorganisation?.organisationName}
                                  </td>
                                  <td>{email}</td>
                                  <td
                                    className={
                                      tableAction
                                        ? Styles["thAction"]
                                        : Styles["trAction"]
                                    }
                                  >
                                    <div className='d-flex gap-3 justify-content-between'>
                                      <Link to={`/admin/contactperson/${id}`}>
                                        <BsEye className={Styles.ViewIcon} />
                                      </Link>
                                      <BiPencil
                                        className={Styles.EditIcon}
                                        onClick={() => {
                                          setcontactToEdit({
                                            id,
                                            fullName,
                                            partnerorganisation,
                                            phoneNumber,
                                            userName,
                                            userType,
                                            email,
                                            createdAt,
                                          });
                                          setopenEditModal(true);
                                        }}
                                      />
                                      <BiTrash
                                        onClick={() => {
                                          setContactToDelete(id);
                                          setopenConfirmationModal(true);
                                        }}
                                        className={Styles.TrashIcon}
                                      />
                                    </div>
                                  </td>
                                </tr>
                              </React.Fragment>
                            );
                          }
                        )
                    )}
                  </tbody>
                </Table>
                {allContactPerson?.length > size && (
                  <ReactPaginate
                    previousLabel={"<<prev"}
                    nextLabel={"next>>"}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    breakLabel={"..."}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    containerClassName={Styles.paginationBtn}
                    previousLinkClassName={Styles.previousBtn}
                    nextLinkClassName={Styles.nextBtn}
                    disabledClassName={Styles.paginationDisabled}
                    activeClassName={Styles.paginationActive}
                  />
                )}
              </section>
            </section>
          </section>
          {/* DIALOG MODAL */}
          <ConfirmationModal
            show={openConfirmationModal}
            onHide={() => setopenConfirmationModal(false)}
            onClickYes={handleDelete}
            onClickNo={() => setopenConfirmationModal(false)}
            instruction='Contact person will be deleted forever'
          />

          {/* EDIT CONTACT PERSON MODAL */}
          <UpdateContactPersonModal
            show={openEditModal}
            onHide={() => setopenEditModal(false)}
            record={contactToEdit}
          />
        </div>
      </React.Fragment>
    </>
  );
};

export default ContactPerson;
