/*

@Beneficiaries

*/

import {
  batchCreatedSuccessToast,
  deleteBatchToast,
  recordAlreadyExistToast,
  somethingWentWrongToast,
  traineeAddedSuccessToast,
  traineeAlreadyExistToast,
  updateBatchSuccessToast,
  updateTraineeRecordSuccessToast,
  uploadProfileSuccess,
  upLoadSuccessfulToast,
} from "../../../components/NotificationToast";
import {
  ADD_BATCH,
  DELETE_BATCH,
  DELETE_BENEFICIARY_BY_ID,
  EDIT_BATCH,
  LOAD_BATCHES,
  LOAD_BENEFICIARY,
  LOAD_BENEFICIARY_BY_ID,
  LOAD_BENEFICIARY_REPORT,
  LOAD_PO_AUDIT_TRAIL,
  LOAD_PO_DASHBOARD_COUNT,
  PO_EDIT_BENEFICIARY,
  PO_LOAD_BENEFICIARY_BY_ID,
} from "../../types";
import POService from "../services/partnerorgs.service";

//Load PO Dashboard count
export const loadPODashboardCount = () => async (dispatch) => {
  try {
    const response = await POService.fetchPODashboardCount();
    dispatch({
      type: LOAD_PO_DASHBOARD_COUNT,
      payload: response.data.data,
    });

    return response;
  } catch (error) {
    somethingWentWrongToast();
  }
};

//@load po audit trail
export const loadPOAuditTrail = () => async (dispatch) => {
  try {
    const response = await POService.fetchPOAuditTrail();
    dispatch({
      type: LOAD_PO_AUDIT_TRAIL,
      payload: response.data.data,
    });
    return response;
  } catch (error) {
    somethingWentWrongToast();
  }
};

// @po create new beneficiary
export const addTrainee = (details, actions) => async (dispatch) => {
  try {
    const response = await POService.newTrainee(details);

    // if(response?.response?.status === 500){
    //   actions.setSubmitting(false);
    //   somethingWentWrongToast();
    // }

    // trainee already exist -email
    if (response?.response?.data?.message == "Validation error") {
      actions.setSubmitting(false);
      traineeAlreadyExistToast();
      return;
    }

    // something went wrong
    if (response?.response?.status === 400) {
      actions.setSubmitting(false);
      somethingWentWrongToast();
      return;
    }

    // trainee record created
    if (response.status === 200) {
      actions.setSubmitting(false);
      actions.resetForm();
      traineeAddedSuccessToast();
    }
    return response;
  } catch (error) {
    actions.setSubmitting(false);
    somethingWentWrongToast();
    return error;
  }
};

//@trainees bulk upload
export const uploadBulkTrainee = (file) => async (dispatch) => {
  try {
    const response = await POService.bulkTraineeUpload(file);
    upLoadSuccessfulToast();
    return response;
  } catch (error) {
    somethingWentWrongToast();
    return error;
  }
};

// get trainee by id
export const loadTraineeById = (id) => async (dispatch) => {
  try {
    const response = await POService.getTraineeByID(id);
    dispatch({
      type: PO_LOAD_BENEFICIARY_BY_ID,
      payload: response.data || null,
    });
    return response;
  } catch (error) {
    somethingWentWrongToast();
    return error;
  }
};

//@po delete trainee record
export const deleteTrainee = (id) => async (dispatch) => {
  try {
    const response = await POService.deleteTrainee(id);

    if (response.status === 200) {
      dispatch({
        type: DELETE_BENEFICIARY_BY_ID,
        payload: id,
      });
      deleteBatchToast();
    }

    return response;
  } catch (error) {
    somethingWentWrongToast();
    return error;
  }
};

/*

@edit trainee record

*/

export const editTraineeRecord =
  (details, setSubmitting) => async (dispatch) => {
    try {
      const response = await POService.updateTraineeRecord(details);

      // something went wrong
      if (response?.response?.status === 400) {
        setSubmitting(false);
        somethingWentWrongToast();
        return;
      }

      if (response.status === 200) {
        updateTraineeRecordSuccessToast();
        setSubmitting(false);
      }

      return response;
    } catch (error) {
      console.log(error);
      setSubmitting(false);
      somethingWentWrongToast();

      return error;
    }
  };

//@ update trainee employment details
export const updateTraineeGraduation =
  (details, setSubmitting) => async (dispatch) => {
    try {
      const response = await POService.updateTraineeGraduation(details);
      if (response.status === 200) {
        updateTraineeRecordSuccessToast();
        setSubmitting(false);
      }

      return response;
    } catch (error) {
      setSubmitting(false);
      somethingWentWrongToast();

      return error;
    }
  };

//@ update trainee trade area/category
export const updateTraineeCategory =
  (newCategory, traineeId, setSubmitting) => async (dispatch) => {
    try {
      const response = await POService.updateTraineeCategory(
        newCategory,
        traineeId
      );
      if (response.status === 200) {
        updateTraineeRecordSuccessToast();
        setSubmitting(false);
      }

      return response;
    } catch (error) {
      setSubmitting(false);
      somethingWentWrongToast();

      return error;
    }
  };

//@ update trainee employment details
export const updateTraineeEmployment =
  (details, setSubmitting) => async (dispatch) => {
    try {
      const response = await POService.updateTraineeEmployment(details);
      if (response.status === 200) {
        updateTraineeRecordSuccessToast();
        setSubmitting(false);
      }

      return response;
    } catch (error) {
      setSubmitting(false);
      somethingWentWrongToast();

      return error;
    }
  };

//@get trainee employment detail
export const loadTraineeEmploymentInfo = (traineeId) => async (dispatch) => {
  try {
    const response = await POService.fetchTraineeEmploymentInfo(traineeId);
  } catch (error) {
    console.log(error);
  }
};

// @fetch PO trainees/beneficiary
export const loadPOTrainees = () => async (dispatch) => {
  try {
    const response = await POService.fetchPOTrainees(1, 500);
    dispatch({
      type: LOAD_BENEFICIARY,
      payload: response.data.data,
    });

    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

// @fetch PO trainees/beneficiary report
export const loadPOTraineesReport = () => async (dispatch) => {
  try {
    const response = await POService.fetchPOTrainees(1, 500);
    dispatch({
      type: LOAD_BENEFICIARY_REPORT,
      payload: response.data.report,
    });

    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

// @fetch po batches
export const loadPOBatches = () => async (dispatch) => {
  try {
    const response = await POService.fetchPOBatches();
    dispatch({
      type: LOAD_BATCHES,
      payload: response.data.data,
    });
    return response;
  } catch (error) {
    return error;
  }
};

// @po create new batch
export const newTradeBatch = (details, actions) => async (dispatch) => {
  try {
    const response = await POService.createTradeBatch(details);
    if (response.status === 200) {
      dispatch({
        type: ADD_BATCH,
        payload: response.data.data,
      });
      batchCreatedSuccessToast();
      actions.setSubmitting(false);
      actions.resetForm({});
      return response;
    }
  } catch (error) {
    actions.setSubmitting(false);
    somethingWentWrongToast();
    return error;
  }
};

/*

@edit batch

*/

export const editTradeBatch = (details, setSubmitting) => async (dispatch) => {
  try {
    const response = await POService.updateTradeBatch(details);
    if (response.status === 200) {
      dispatch({
        type: EDIT_BATCH,
        payload: details,
      });

      updateBatchSuccessToast();
      setSubmitting(false);
    }

    return response;
  } catch (error) {
    setSubmitting(false);
    somethingWentWrongToast();

    return error;
  }
};

//@po delete batch
export const deleteBatch = (id) => async (dispatch) => {
  try {
    const response = await POService.deleteBatch(id);

    if (response.status === 200) {
      dispatch({
        type: DELETE_BATCH,
        payload: id,
      });
      deleteBatchToast();
    }

    return response;
  } catch (error) {
    somethingWentWrongToast();
    return error;
  }
};

//@profile image upload
export const updateTraineeProfileImage =
  (file, traineeId) => async (dispatch) => {
    try {
      const response = await POService.uploadTraineeProfileImage(
        file,
        traineeId
      );

      if (response.status === 200) {
        dispatch(loadTraineeById(traineeId));
        uploadProfileSuccess();
      }
    } catch (error) {
      somethingWentWrongToast();
      return error;
    }
  };

//@Cv upload
export const updateTraineeCV = (file, traineeId) => async (dispatch) => {
  try {
    const response = await POService.uploadTraineeCV(file, traineeId);

    if (response.status === 200) {
      dispatch(loadTraineeById(traineeId));
      upLoadSuccessfulToast();
    }
  } catch (error) {
    somethingWentWrongToast();
    return error;
  }
};
