// importing actions

import {
  ADD_CITY,
  ADD_STATE,
  ADD_ZONE,
  LOAD_CITIES,
  LOAD_PO_STATES,
  LOAD_STATES,
  LOAD_STATE_LGA,
  LOAD_ZONES,
} from "../../types";

// state
const INITIAL_STATE = {
  cities: null,
  states: null,
  zones: null,
  lga: null,
  poStates: null
};

// Redux reducer function
const locationReducer = (state = INITIAL_STATE, action) => {
  // Destructuring action
  const { type, payload } = action;

  switch (type) {
    case ADD_CITY:
      return {
        ...state,
        cities: [...state.cities, payload],
      };

    case LOAD_CITIES:
      return {
        ...state,
        cities: payload,
      };

    case LOAD_STATE_LGA:
      return {
        ...state,
        lga: payload,
      };

    case ADD_STATE:
      return {
        ...state,
        states: [...state.states, payload],
      };

    case LOAD_STATES:
      return {
        ...state,
        states: payload,
      };
    case ADD_ZONE:
      return {
        ...state,
        zones: [...state.zones, payload],
      };

    case LOAD_ZONES:
      return {
        ...state,
        zones: payload,
      };

    case LOAD_PO_STATES:
      return {
        ...state,
        poStates: payload,
      };

    default:
      return state;
  }
};

export default locationReducer;
