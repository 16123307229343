import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Importing of Libraries
import { useMediaQuery } from "react-responsive";
// import { BsSearch } from 'react-icons/bs';
// import { IoMdNotificationsOutline } from 'react-icons/io';
// import { SiGooglemessages } from 'react-icons/si';
import { RiMenu2Line } from "react-icons/ri";
import { FaTimes } from "react-icons/fa";

// Importing Styles
import Styles from "./Navbar.module.css";
// import { loadPOByID } from "../../redux/admin/actions/admin.actions";

const Navbar = ({ title, handleHamburger, sethandleHamburger }) => {
  // States
  // const [showInput, setshowInput] = useState(false);
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.userReducer);
  const [poName, setpoName] = useState("");

  // Hamburger
  const isHamburger = useMediaQuery({
    query: "(max-width: 1224px)",
  });

  // Using React Responsive
  // const isMobile = useMediaQuery({
  //   query: '(min-width: 426px)',
  // });

  // const isMobile2 = useMediaQuery({
  //   query: '(min-width: 374px)',
  // });

  // useEffect(() => {
  //   if (userData) {
  //     dispatch(loadPOByID(userData?.partnerorganisationId)).then((response) => {
  //       console.log(response);
  //       setpoName(response.data.name);
  //     });
  //   }
  // }, [userData]);

  return (
    <div className={Styles.navbarCont}>
      <div className={Styles.navbarContInner}>
        <div className={Styles.dashboardName}>
          {" "}
          {/* <span
            className={Styles.navbarHamburger}
            onClick={() => sethandleHamburger(!handleHamburger)}
          >
            {isHamburger && !handleHamburger ? (
              <RiMenu2Line />
            ) : isHamburger && handleHamburger ? (
              <FaTimes />
            ) : (
              ""
            )}
          </span> */}
          <p className={`${Styles.dashboardName} d-none d-md-block`}>
            {title || "Dashboard"}
          </p>
        </div>
        <div className={Styles.navContentLeft}>
          <div className={Styles.profileCont}>
            <div className='d-flex flex-column'>
              <div className='m-4 position-relative'>
                <p className={Styles.tsdp}>
                  ITF-NECA Technical Skills Development Program (TSDP)
                </p>

                {userData?.userType === "partnerOrganization" && (
                  <div className={`${Styles.profileDetails} ${Styles.poName}`}>
                    <p className={Styles.profileRole}>
                      {userData?.partnerorganisation?.organisationName
                        ?.split(" ")
                        ?.slice(0, 6)
                        ?.join(" ")}
                    </p>
                  </div>
                )}
              </div>
            </div>

            <span
              className={Styles.navbarHamburger}
              onClick={() => sethandleHamburger(!handleHamburger)}
            >
              {isHamburger && !handleHamburger ? (
                <RiMenu2Line />
              ) : (
                isHamburger &&
                handleHamburger && (
                  <div>
                    <FaTimes />
                  </div>
                )
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
