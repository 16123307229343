/* 
@example 
    
    export const ENDPOINT_ACTION_NAME = "/endpoint-url"
*/

// @Authentication/User
export const USER_LOGIN_URL = "/auth/signin";

export const FETCH_USER_PROFILE_URL = "/users/profile";
export const FETCH_TRAINEE_PROFILE_URL = "/beneficiaries/trainee/profile";
export const CHANGE_PASSWORD_URL = "/users/change-password";
export const REQUEST_FORGOT_PASSWORD_RESET_URL = "/users/forgot-password";
export const RESET_FORGOT_PASSWORD_URL = "/users/reset-forgot-password";
