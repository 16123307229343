import {
  contactPersonCreate,
  poCreateSuccessToast,
  somethingWentWrongToast,
  deletePOSuccessToast,
  deleteContactPersonToast,
  updateContactPersonSuccessToast,
  poUpdatedSuccessToast,
  recordAlreadyExistToast,
  upLoadSuccessfulToast,
} from '../../../components/NotificationToast';
import {
  LOAD_ALL_CONTACT_PERSON,
  VIEW_CONTACT_PERSON_BY_ID,
  LOAD_PARTNER_ORGS,
  VIEW_PARTNER_ORG_BY_ID,
  LOAD_BENEFICIARIES,
  VIEW_BENEFICIARY_BY_ID,
  DELETE_PO,
  DELETE_CONTACT_PERSON,
  EDIT_CONTACT_PERSON,
  EDIT_PO,
  LOAD_DASHBOARD_COUNT,
  LOAD_TRAINEE_REPORT,
  LOAD_ADMIN_AUDIT_TRAIL,
} from '../../types';
import AdminService from '../services/admin.service';

//Load dashboard count
export const loadDashboardCount = () => async (dispatch) => {
  try {
    const response = await AdminService.fetchDashboardCount();

    dispatch({
      type: LOAD_DASHBOARD_COUNT,
      payload: response.data.data,
    });

    return response;
  } catch (error) {
    somethingWentWrongToast();
  }
};

//@load admin audit trail
export const loadAdminAuditTrail = () => async (dispatch) => {
  try {
    const response = await AdminService.fetchAdminAuditTrail();
    dispatch({
      type: LOAD_ADMIN_AUDIT_TRAIL,
      payload: response.data.data,
    });
  } catch (error) {
    somethingWentWrongToast();
  }
};

// Load All POs
export const loadPartnerOrgs =
  (pageNumber = '', size = '') =>
  async (dispatch) => {
    try {
      const response = await AdminService.fetchAllPO(pageNumber, size);

      dispatch({
        type: LOAD_PARTNER_ORGS,
        payload: response.data.data,
      });

      return response;
    } catch (error) {
      somethingWentWrongToast();
    }
  };

// Create new PO
export const addNewPO = (details, actions) => async (dispatch) => {
  try {
    const response = await AdminService.createNewPO(details);

    if (response?.response?.data?.message === 'Validation error') {
      recordAlreadyExistToast();
      return;
    }

    if (
      response?.response?.status === 404 ||
      response?.response?.status === 401
    ) {
      somethingWentWrongToast();
      actions.setSubmitting(false);
      return;
    }

    if (response.status === 200) {
      poCreateSuccessToast();
      actions.setSubmitting(false);
      actions.resetForm();
      return response;
    }

    somethingWentWrongToast();
    actions.setSubmitting(false);

    return response;
  } catch (error) {
    actions.setSubmitting(false);
    somethingWentWrongToast();
    return error;
  }
};

//@po bulk upload
export const uploadBulkPO = (file) => async (dispatch) => {
  try {
    const response = await AdminService.bulkPOUpload(file);
    upLoadSuccessfulToast();
    return response;
  } catch (error) {
    somethingWentWrongToast();
    return error;
  }
};

// get PO by id
export const loadPOByID = (id) => async (dispatch) => {
  try {
    const response = await AdminService.getPOByID(id);
    dispatch({
      type: VIEW_PARTNER_ORG_BY_ID,
      payload: response.data || null,
    });
  } catch (error) {
    somethingWentWrongToast();
  }
};

/*

@ delete PO by ID
*/

export const deletePoByID = (id) => async (dispatch) => {
  try {
    const response = await AdminService.deletePO(id);
    dispatch({
      type: DELETE_PO,
      payload: id,
    });
    deletePOSuccessToast();

    return response;
  } catch (error) {
    console.log(error);
    somethingWentWrongToast();
    return error;
  }
};

/*

@edit Po record

*/

export const editPO = (details, setSubmitting) => async (dispatch) => {
  try {
    const response = await AdminService.updatePO(details);
    dispatch({
      type: EDIT_PO,
      payload: details,
    });

    poUpdatedSuccessToast();
    setSubmitting(false);

    return response;
  } catch (error) {
    setSubmitting(false);
    somethingWentWrongToast();

    return error;
  }
};

/*

@Contact person

*/

// @Add contact person
export const addContactPerson =
  (newContact, formActions) => async (dispatch) => {
    try {
      const response = await AdminService.createContactPerson(newContact);

      if (response?.status === 200 || response?.status === true) {
        // update ui
        contactPersonCreate();

        // stop submitting form
        formActions.setSubmitting(false);
        formActions.resetForm();
      }
    } catch (error) {
      somethingWentWrongToast();
      formActions.setSubmitting(false);
    }
  };

/*

@edit contact person

*/

export const editContactPerson =
  (details, setSubmitting) => async (dispatch) => {
    try {
      const response = await AdminService.updateContactPerson(details);
      dispatch({
        type: EDIT_CONTACT_PERSON,
        payload: details,
      });

      updateContactPersonSuccessToast();
      setSubmitting(false);

      return response;
    } catch (error) {
      setSubmitting(false);
      somethingWentWrongToast();

      return error;
    }
  };

//@fetch all contact person
export const loadAllContactPerson = () => async (dispatch) => {
  try {
    const response = await AdminService.fetchAllContactPerson();
    dispatch({
      type: LOAD_ALL_CONTACT_PERSON,
      payload: response.data.data,
    });
  } catch (error) {
    somethingWentWrongToast();
  }
};

// get Contact person by id
export const loadContactPersonByID = (id) => async (dispatch) => {
  try {
    const response = await AdminService.getContactPersonByID(id);

    dispatch({
      type: VIEW_CONTACT_PERSON_BY_ID,
      payload: response.data || null,
    });

    return response;
  } catch (error) {
    somethingWentWrongToast();
    return error;
  }
};

/*

@ delete Contact Person by ID
*/

export const deleteContactPersonByID = (id) => async (dispatch) => {
  try {
    const response = await AdminService.deleteContactPerson(id);
    dispatch({
      type: DELETE_CONTACT_PERSON,
      payload: id,
    });
    deleteContactPersonToast();

    return response;
  } catch (error) {
    somethingWentWrongToast();
    return error;
  }
};

//@fetch all beneficiaries
export const loadAllBeneficiaries =
  (pageNumber = '', size = '') =>
  async (dispatch) => {
    try {
      const response = await AdminService.fetchAllBeneficiaries(
        pageNumber,
        size
      );
      dispatch({
        type: LOAD_BENEFICIARIES,
        payload: response.data.data,
      });
    } catch (error) {
      somethingWentWrongToast();
    }
  };

// get Beneficiary by id
export const loadBeneficiaryByID = (id) => async (dispatch) => {
  try {
    const response = await AdminService.getBeneficiaryByID(id);

    dispatch({
      type: VIEW_BENEFICIARY_BY_ID,
      payload: response.data || null,
    });
  } catch (error) {
    somethingWentWrongToast();
  }
};

// @fetch trainees reports
export const loadTraineeReport = () => async (dispatch) => {
  try {
    const response = await AdminService.fetchTrainingReport();
    dispatch({
      type: LOAD_TRAINEE_REPORT,
      payload: response.data.data,
    });

    // return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};
