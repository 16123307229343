import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";

// Toast
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// PO Layout
import PODashboardLayout from "./screens/PartnerOrgs/PODashboardLayout/PODashboardLayout";

import AdminDashboardLayout from "./screens/Admin/AdminDashboardLayout/AdminDashboardLayout";
import PartnerOrgs from "./screens/Admin/PartnerOrgs/PartnerOrgs";
import ContactPerson from "./screens/Admin/ContactPerson/ContactPerson";

import ErrorBoundary from "./components/ErrorBoundary";
import CircularLoader from "./components/Loader/CircularLoader";
import { BeneficiaryDashboard } from "./screens/Beneficiary/BeneficiaryDashboard/BeneficiaryDashboard";
import BeneficiaryDashboardLayout from "./screens/Beneficiary/BeneficiaryDashboardLayout/BeneficiaryDashboardLayout";
import ManageState from "./screens/Admin/Settings/States/ManageState";

// 404
const PageNotFound = React.lazy(() => import("./components/PageNotFound"));

// Importing Admin pages
const Dashboard = React.lazy(() =>
  import("./screens/Admin/Dashboard/Dashboard")
);
const Login = React.lazy(() => import("./screens/Admin/Login/LoginScreen"));
const ForgotPassword = React.lazy(() =>
  import("./screens/Admin/Login/ForgotPassword")
);
const UpdatePassword = React.lazy(() =>
  import("./screens/Admin/Login/UpdatePassword")
);
const About = React.lazy(() => import("./screens/About/About"));
const AddPartnerOrgs = React.lazy(() =>
  import("./screens/Admin/PartnerOrgs/AddPartnerOrgs")
);
const PartnerOrgsBulkUpload = React.lazy(() =>
  import("./screens/Admin/PartnerOrgs/PartnerOrgsBulkUpload")
);
const ViewPartnerOrgs = React.lazy(() =>
  import("./screens/Admin/PartnerOrgs/ViewPartnerOrgs")
);
const AddContactPerson = React.lazy(() =>
  import("./screens/Admin/ContactPerson/AddContactPerson")
);
const ViewContactPerson = React.lazy(() =>
  import("./screens/Admin/ContactPerson/ViewContactPerson")
);
const TradeArea = React.lazy(() =>
  import("./screens/Admin/TradeAreas/TradeArea")
);
const ViewPoCategory = React.lazy(() =>
  import("./screens/Admin/TradeAreas/ViewPoCategory")
);

const ViewCategory = React.lazy(() =>
  import("./screens/Admin/TradeAreas/ViewCategory")
);
const Trainees = React.lazy(() => import("./screens/Admin/Trainees/Trainees"));
const ViewTrainee = React.lazy(() =>
  import("./screens/Admin/Trainees/ViewTrainee")
);
const Settings = React.lazy(() => import("./screens/Admin/Settings/Settings"));
const Profile = React.lazy(() => import("./screens/Admin/Settings/Profile"));
const UserPermissions = React.lazy(() =>
  import("./screens/Admin/Settings/UserPermissions")
);
const AddPermission = React.lazy(() =>
  import("./screens/Admin/Settings/AddPermission")
);
const StateReports = React.lazy(() =>
  import("./screens/Admin/Reports/StateReports")
);
const BeneficiariesReports = React.lazy(() =>
  import("./screens/Admin/Reports/BeneficiariesReports")
);
const GenderReports = React.lazy(() =>
  import("./screens/Admin/Reports/GenderReports")
);
const GraduationReports = React.lazy(() =>
  import("./screens/Admin/Reports/GraduationReports")
);
const TrainingReports = React.lazy(() =>
  import("./screens/Admin/Reports/TrainingReports")
);
const PdfReports = React.lazy(() =>
  import("./screens/Admin/Reports/PdfReports")
);

const StateLists = React.lazy(() =>
  import("./screens/Admin/Settings/States/StateLists")
);

// Importing PO pages

const PODashboard = React.lazy(() =>
  import("./screens/PartnerOrgs/PODashboard/")
);
const POSettings = React.lazy(() => import("./screens/PartnerOrgs/POSettings"));
const POSupport = React.lazy(() => import("./screens/PartnerOrgs/POSupport"));
const POTrainees = React.lazy(() => import("./screens/PartnerOrgs/POTrainees"));
const PONewTrainee = React.lazy(() =>
  import("./screens/PartnerOrgs/PONewTrainee")
);
const POTraineeBulkUpload = React.lazy(() =>
  import("./screens/PartnerOrgs/POTraineeBulkUpload")
);
const POViewTrainee = React.lazy(() =>
  import("./screens/PartnerOrgs/POViewTrainee")
);
const POTradeAreas = React.lazy(() =>
  import("./screens/PartnerOrgs/POTradeAreas")
);
const POAddTradeArea = React.lazy(() =>
  import("./screens/PartnerOrgs/POAddTradeArea")
);
const POTrainingBatch = React.lazy(() =>
  import("./screens/PartnerOrgs/POTrainingBatch")
);
const PONewTrainingBatch = React.lazy(() =>
  import("./screens/PartnerOrgs/PONewTrainingBatch")
);
const POReports = React.lazy(() => import("./screens/PartnerOrgs/POReports"));
const POEditTraineeRecord = React.lazy(() =>
  import("./screens/PartnerOrgs/POEditTraineeRecord")
);
const ChangePassword = React.lazy(() =>
  import("./screens/PartnerOrgs/POSettings/ChangePassword")
);

function App() {
  return (
    <React.Fragment>
      <ErrorBoundary>
        <Suspense
          fallback={
            <div className='d-flex justify-content-center align-items-center min-vh-100'>
              {/* Loading Please Wait... */}
              <CircularLoader />
            </div>
          }
        >
          <Provider store={store}>
            <ToastContainer
              position='top-right'
              autoClose={5000}
              hideProgressBar={true}
            />
            <Router>
              <Routes>
                {/* Default Page */}
                <Route path='/' element={<Login />} />
                <Route path='/forgot' element={<ForgotPassword />}></Route>
                <Route
                  path='/updatepassword'
                  element={<UpdatePassword />}
                ></Route>
                <Route path='/about' element={<About />}></Route>

                {/* Admin Dashboard Routes */}
                <Route path='/admin' element={<AdminDashboardLayout />}>
                  <Route index element={<Dashboard />} />
                  <Route path='support' element={<POSupport />} />
                  <Route path='change-password' element={<ChangePassword />} />
                  <Route path='partnerOrg'>
                    <Route index element={<PartnerOrgs />} />
                    <Route path='add' element={<AddPartnerOrgs />} />
                    <Route
                      path='po-bulk-upload'
                      element={<PartnerOrgsBulkUpload />}
                    />
                    <Route path=':id' element={<ViewPartnerOrgs />} />
                  </Route>
                  <Route path='contactperson'>
                    <Route index element={<ContactPerson />} />
                    <Route path='add' element={<AddContactPerson />} />
                    <Route path=':id' element={<ViewContactPerson />} />
                  </Route>
                  <Route path='trainees'>
                    <Route index element={<Trainees />} />
                    <Route path=':id' element={<ViewTrainee />} />
                  </Route>
                  <Route path='tradeareas'>
                    <Route index element={<TradeArea />} />
                    <Route path='viewcategory' element={<ViewCategory />} />
                    <Route path=':id' element={<ViewPoCategory />} />
                  </Route>
                  <Route path='settings'>
                    <Route index element={<Settings />} />
                    <Route path='profile' element={<Profile />} />
                    <Route path='states' element={<StateLists />} />
                    <Route path='states/:stateId' element={<ManageState />} />
                  </Route>
                  <Route path='userpermissions'>
                    <Route index element={<UserPermissions />} />
                    <Route path='add' element={<AddPermission />} />
                  </Route>

                  {/* ADMIN reports routes */}
                  <Route path='reports'>
                    <Route index element={<StateReports />} />
                    <Route
                      path='beneficiaries'
                      element={<BeneficiariesReports />}
                    />
                    <Route path='training' element={<TrainingReports />} />
                    <Route path='graduation' element={<GraduationReports />} />
                    <Route path='gender' element={<GenderReports />} />
                    <Route path='pdf' element={<PdfReports />} />
                  </Route>
                </Route>

                {/* PO ROUTES */}
                <Route path='podashboard' element={<PODashboardLayout />}>
                  <Route index element={<PODashboard />} />
                  <Route path='settings' element={<POSettings />} />
                  <Route path='change-password' element={<ChangePassword />} />
                  <Route path='support' element={<POSupport />} />
                  <Route path='trainees'>
                    <Route index element={<POTrainees />} />
                    <Route path='add' element={<PONewTrainee />} />
                    <Route
                      path='trainee-bulk-upload'
                      element={<POTraineeBulkUpload />}
                    />
                    <Route
                      path='update/:traineeId'
                      element={<POEditTraineeRecord />}
                    />
                    <Route path=':traineeId' element={<POViewTrainee />} />
                    <Route path='trainingbatch' element={<POTrainingBatch />} />
                    <Route
                      path='createbatch'
                      element={<PONewTrainingBatch />}
                    />
                  </Route>

                  {/* PO reports routes */}
                  <Route path='reports'>
                    <Route index element={<POReports />} />
                    <Route
                      path='beneficiaries'
                      element={<BeneficiariesReports />}
                    />
                    <Route path='training' element={<TrainingReports />} />
                    <Route path='graduation' element={<GraduationReports />} />
                    <Route path='gender' element={<GenderReports />} />
                    <Route path='pdf' element={<PdfReports />} />
                  </Route>

                  {/* po trade area routes */}
                  <Route path='tradeareas'>
                    <Route index element={<POTradeAreas />} />
                    <Route path='add' element={<POAddTradeArea />} />
                  </Route>
                </Route>

                {/* beneficiary routes */}
                <Route path='trainee' element={<BeneficiaryDashboardLayout />}>
                  <Route index element={<BeneficiaryDashboard />} />
                  <Route
                    path='update-profile/:traineeId'
                    element={<POEditTraineeRecord />}
                  />
                  {/*  */}
                  <Route path='change-password' element={<ChangePassword />} />
                  <Route path='support' element={<POSupport />} />
                </Route>

                {/* Page not found */}
                <Route path='*' element={<PageNotFound />} />
              </Routes>
            </Router>
          </Provider>
        </Suspense>
      </ErrorBoundary>
    </React.Fragment>
  );
}

export default App;
