import { Button, Modal } from "react-bootstrap";

import Styles from "./ConfirmationModal.module.css";

export const ConfirmationModal = (props) => {
  return (
    <Modal
      {...props}
      size='sm'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      className='rounded'
    >
      <Modal.Header closeButton className='border-0'></Modal.Header>
      <Modal.Body className='d-flex flex-column justify-content-center align-items-center gap-3 pb-5'>
        <h4 className={Styles.instruction}>
          {props.message || "Are you sure?"}
        </h4>
        {props.instruction && (
          <span className='alert alert-danger text-center' role='alert'>
            {props.instruction}
          </span>
        )}
        <section>
          <Button
            className={` shadow-none ${Styles.actionBtn} ${Styles.confirm}`}
            onClick={() => props.onClickYes()}
          >
            Yes
          </Button>
          <Button
            className={` shadow-none ${Styles.actionBtn} ${Styles.reject}`}
            onClick={() => props.onClickNo()}
          >
            No
          </Button>
        </section>
      </Modal.Body>
    </Modal>
  );
};
