import { combineReducers } from "redux";
import adminReducer from "./admin/reducers/admin.reducers";
import partnerorgsReducer from "./partnerOrgs/reducers/partnerorgs.reducers";
import userReducer from "./user/reducers/user.reducers";
import locationReducer from "./locations/reducers/locations.reducers";
import tradeReducer from "./trade/reducers/trade.reducers";
import reportReducer from "./reports/reducers/reports.reducers";

const rootReducer = combineReducers({
  adminReducer,
  partnerorgsReducer,
  userReducer,
  locationReducer,
  tradeReducer,
  reportReducer
});

export default rootReducer;
