/* 
@example 
    
    export const ENDPOINT_ACTION_NAME = "/endpoint-url"
*/

export const BENEFICIARY_URL = "/beneficiaries";
export const ADD_BENEFICIARY_IN_BULK_URL = "/beneficiaries/import/csv";
export const PARTNER_ORG_TRAINEES = BENEFICIARY_URL + "/po/trainees";

export const TRAINING_BATCH_URL = "/training-batch";
export const PO_TRAINING_BATCH_URL = "/training-batch/po/batches";

//@employment update
export const TRAINEE_EMPLOYMENT_URL = "/employ";
export const GET_TRAINEE_EMPLOYMENT_INFO_URL = "/employ";

//@graduation update
export const TRAINEE_GRADUATION_URL = "/evicted";

//@category update
export const TRAINEE_CATEGORY_URL = BENEFICIARY_URL + "/categories/";

//@dashboard count
export const PO_DASHBOARD_COUNT = "/dashboardPo";

//@file upload
export const FILE_UPLOAD_URL = "/file-upload";

//@audit
export const FETCH_PO_AUDIT_TRAIL_URL = "/audit-trail";
