/* 
@example

    export const ACTION_NAME = "ACTION_NAME"
*/

/*

  @Authentications/User
*/

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOAD_USER_PROFILE = 'LOAD_USER_PROFILE';

/*

 @PO

*/

// - beneficiaries

export const ADD_BENEFICIARY = 'ADD_BENEFICIARY';
export const LOAD_BENEFICIARY = 'LOAD_BENEFICIARY';
export const CLEAR_BENEFICIARY = 'CLEAR_BENEFICIARY';
export const LOAD_BENEFICIARY_REPORT = 'LOAD_BENEFICIARY_REPORT';
export const DELETE_BENEFICIARY_BY_ID = 'DELETE_BENEFICIARY_BY_ID';
export const PO_LOAD_BENEFICIARY_BY_ID = 'PO_LOAD_BENEFICIARY_BY_ID';
export const PO_EDIT_BENEFICIARY = 'PO_EDIT_BENEFICIARY';

/*

 @Admin

*/

// - dashboard count
export const LOAD_DASHBOARD_COUNT = 'LOAD_DASHBOARD_COUNT';

// - contact persons
export const LOAD_ALL_CONTACT_PERSON = 'LOAD_ALL_CONTACT_PERSON';
export const VIEW_CONTACT_PERSON_BY_ID = 'VIEW_CONTACT_PERSON_BY_ID';
export const EDIT_CONTACT_PERSON = 'EDIT_CONTACT_PERSON';

// @Notifications

// @Create user
export const USER_CREATE_SUCCESS = 'USER_CREATE_SUCCESS';

// @Create contact person
export const CREATE_CONTACT_PERSON = 'CREATE_CONTACT_PERSON';
export const LOAD_CONTACT_PERSONS = 'LOAD_CONTACT_PERSONS';
export const DELETE_CONTACT_PERSON = 'DELETE_CONTACT_PERSON';

// - city
export const ADD_CITY = 'ADD_CITY';
export const LOAD_CITIES = 'LOAD_CITIES';
// - state
export const ADD_STATE = 'ADD_STATE';
export const LOAD_STATES = 'LOAD_STATES';
//-lga
export const LOAD_STATE_LGA = 'LOAD_STATE_LGA';
// - zone
export const ADD_ZONE = 'ADD_ZONE';
export const LOAD_ZONES = 'LOAD_ZONES';

// @Trade Areas
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const EDIT_CATEGORY = 'EDIT_CATEGORY';
export const LOAD_CATEGORIES = 'LOAD_CATEGORIES';
export const LOAD_PO_TRADE_AREAS = 'LOAD_PO_TRADE_AREAS';
export const LOAD_TRADE_AREA_PO = 'LOAD_TRADE_AREA_PO';

//@ Batches
export const LOAD_BATCHES = 'LOAD_BATCHES';
export const ADD_BATCH = 'ADD_BATCH';
export const DELETE_BATCH = 'DELETE_BATCH';
export const EDIT_BATCH = 'EDIT_BATCH';

// @Partner Organizations
export const LOAD_PO_DASHBOARD_COUNT = 'LOAD_PO_DASHBOARD_COUNT';
export const LOAD_PARTNER_ORGS = 'LOAD_PARTNER_ORGS';
export const VIEW_PARTNER_ORG_BY_ID = 'VIEW_PARTNER_ORG_BY_ID';
export const DELETE_PO = 'DELETE_PO';
export const EDIT_PO = 'EDIT_PO';
export const LOAD_PARTNER_ORG_TRADE_AREA = 'LOAD_PARTNER_ORG_TRADE_AREA';
export const LOAD_PO_STATES = "LOAD_PO_STATES"

//@Beneficiaries admin
export const LOAD_BENEFICIARIES = 'LOAD_BENEFICIARIES';
export const VIEW_BENEFICIARY_BY_ID = 'VIEW_BENEFICIARY_BY_ID';

//@Audit trails
export const LOAD_ADMIN_AUDIT_TRAIL = 'LOAD_ADMIN_AUDIT_TRAIL';
export const LOAD_PO_AUDIT_TRAIL = 'LOAD_PO_AUDIT_TRAIL';

//@Trainee Reports
export const LOAD_TRAINEE_REPORT = 'LOAD_TRAINEE_REPORT';
export const LOAD_STATE_REPORT = 'LOAD_STATE_REPORT';
export const LOAD_GENDER_REPORT = 'LOAD_GENDER_REPORT';
export const LOAD_TRADE_AREA_REPORT = 'LOAD_TRADE_AREA_REPORT';
export const LOAD_GRADUATION_REPORT = 'LOAD_GRADUATION_REPORT';
